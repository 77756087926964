import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Pagination, Modal } from "antd";
import { defaultImage } from "../../../assets/images";
import {
  UserOutlined,
  ShoppingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { user } from "../../../assets/images/index";
import {
  checkOrderHistory,
  UpdateOrderStatus,
} from "../../../services/order/orderService";
import { formatPrice, formatMoney } from "../../../utils/helpers";
import i18n from "../../../i18n";
import { addToCart } from "../../../actions/addToCartAction";
import { format } from "date-fns";

const History = () => {
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [orderHistory, setOrderHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orderHistory.slice(indexOfFirstItem, indexOfLastItem);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchOrderHistory = async () => {
    try {
      const response = await checkOrderHistory(userInfo.data.token);
      const orders = response.data.orders;
      setOrderHistory(orders);
    } catch (error) {}
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return t("PENDING");
      case 1:
        return t("PAID");
      case 2:
        return t("COMPLETE");
      case 3:
        return t("CANCELED");
      case 4:
        return t("SHIPPING");
      default:
        return "";
    }
  };

  const handleCancel = async (orderId) => {
    try {
      await UpdateOrderStatus(orderId, 3, userInfo.data.token);
      setUpdate(!update);
    } catch (error) {}
  };

  const showCancelModal = (orderId) => {
    setCancelOrderId(orderId);
    setIsModalVisible(true);
  };

  const handleCancelConfirm = () => {
    if (cancelOrderId) {
      handleCancel(cancelOrderId);
      setIsModalVisible(false);
      setCancelOrderId(null);
    }
  };

  const handleCancelClose = () => {
    setIsModalVisible(false);
    setCancelOrderId(null);
  };

  function getNewCartUIId() {
    const lastId = parseInt(localStorage.getItem("lastCartIdUI")) || 0;
    const newId = lastId + 1;
    localStorage.setItem("lastCartIdUI", newId);
    return newId;
  }
  const handleEdit = async (order) => {
    let updatedCartItems = [];
    for (const orderItem of order.orderItems) {
      const IdUI = getNewCartUIId();
      const newItem = {
        IdUI,
        id: orderItem?.product.id,
        name: orderItem?.product?.name,
        name_en: orderItem?.product?.name_en,
        quantity: orderItem?.quantity,
        attribute_id: orderItem?.attribute_id,
        price: orderItem?.attribute?.product_price,
        size: orderItem?.attribute?.product_size,
        category: orderItem?.product?.category.name,
        category_en: orderItem?.product?.category?.name_en,
        image: orderItem?.product?.image,
        type: orderItem?.type,
        orderId: order.id,
        shipment: order?.shipment?.address,
      };
      updatedCartItems.push(newItem);
    }
    localStorage.setItem("cartProducts", JSON.stringify(updatedCartItems));
    dispatch(addToCart(updatedCartItems));
    localStorage.setItem("cartCount", updatedCartItems?.length);
    window.dispatchEvent(
      new CustomEvent("cartUpdated", { detail: updatedCartItems?.length })
    );
    window.location.href = "/cart";
  };
  useEffect(() => {
    localStorage.removeItem("orderDetail");
    fetchOrderHistory();
  }, [userInfo?.data?.token, update]);
  const [languageChange, setLanguageChange] = useState(i18n.language);
  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);

  if (!userInfo) {
    return (
      <div className="text-center py-10">Please log in to view this page.</div>
    );
  }

  return (
    <div className="w-full bg-[#F2EBE6] flex justify-center items-start flex-grow">
      <div className="w-full h-full lg:w-4/5 md:flex mb-10 mt-24">
        <div className="md:w-1/3 w-full mt-4 md:mt-20 mb-6 px-8  md:px-0">
          <div className="bg-white rounded-xl shadow-lg py-4 px-6 lg:py-6 lg:px-8">
            <div className="flex flex-col items-center text-base md:text-lg border-b border-gray-400">
              <img
                src={user}
                alt="userLogo"
                className="rounded-full mt-2 md:mt-0 ml-4 mb-4 md:mb-0 size-20"
              />
              <div className="md:ml-4 mt-4 md:mt-0 mb-4 text-center">
                <h2 className="font-bold lg:text-xl mb-1 md:mb-0">
                  {userInfo.data.name}
                </h2>
                <p className="font-semibold text-[#B08972] mb-1 md:mb-0">
                  {t(`Loyal customer`)}
                </p>
              </div>
            </div>
            <div className="mt-10 ml-6 text-base">
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/user_profile"
                    className="text-[#191818] hover:text-[#B08972]"
                  >
                    <span className="flex items-center">
                      <UserOutlined className="mr-2 text-[#1029e782]" />
                      {t("Account")}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="" className="text-[#B08972]">
                    <span className="flex items-center">
                      <ShoppingOutlined className="mr-2 text-[#1029e782]" />
                      {t("Orders")}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="md:w-3/4 md:ml-4 px-8  md:px-0 md:mt-20 overflow-auto">
          <div className="bg-white rounded-xl py-12 px-8">
            {orderHistory?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <h2 className="text-lg">{t("NoProductBuy")}</h2>
              </div>
            ) : (
              <div>
                {currentItems?.map((order) => (
                  <div key={order?.id}>
                    <div className="border h-1/3 px-auto border-gray-200 shadow-lg rounded-lg p-6 mb-4 relative">
                      <div className="flex items-center test-lg border-b border-gray-300 mb-4 ">
                        <Link
                          to={`/order_detail/${order?.id}`}
                          className="flex justify-start flex-col text-black hover:text-red-400"
                        >
                          <button className="px-4 py-2 bg-red-300 hover:bg-red-200 text-white text-lg font-bold shadow-lg rounded-md mr-2 mb-4 ">
                            <MenuOutlined /> {t("View Detail")}
                          </button>
                        </Link>
                      </div>
                      <div className="justify-around overflow-y-auto flex w-full flex-col text-base border-b border-gray-300 md:text-base ">
                        <div className="overflow-y-auto max-h-[400px] ">
                          {order?.orderItems?.map((product) => {
                            return (
                              <div
                                key={product?.id}
                                className="flex flex-col md:justify-start mt-2 md:p-2 font-semibold "
                              >
                                <div className="flex items-center bg-gray-200 rounded-lg shadow-lg py-2 md:py-4 md:px-4">
                                  <img
                                    src={
                                      product?.product?.image
                                        ? product?.product?.image
                                        : defaultImage
                                    }
                                    alt="productImage"
                                    className="flex-shrink-0 shadow-lg rounded-md size-20"
                                  />
                                  <div className="justify-between ml-4 lg:flex p-3 mx-auto">
                                    <p className="text-sm">
                                      {languageChange === "en"
                                        ? product?.product?.name_en
                                        : product?.product?.name}
                                    </p>
                                    <p className="text-sm lg:ml-4">
                                      Size: {product?.attribute?.product_size}
                                    </p>
                                  </div>
                                  <div className="text-right">
                                    <p className="text-md text-right text-[#f36406f8]">
                                      {formatMoney(
                                        product?.attribute?.product_price
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div></div>
                      <div className="text-right border-b border-gray-300 mt-2 mr-2">
                        <p className="text-gray-600 font-semibold mb-6 ml-4 text-right">
                          {t("Order time")}:{" "}
                          {order?.updatedAt
                            ? format(
                                new Date(order?.updatedAt),
                                "HH:mm:ss dd/MM/yy"
                              )
                            : ""}
                        </p>
                        <p className="text-gray-600 font-semibold mb-6 ml-4 text-right">
                          {t("Status")}: {getStatusLabel(order?.status)}
                        </p>
                        {getStatusLabel(order?.status) === t("PENDING") && (
                          <>
                            <button
                              className="px-4 py-2 bg-red-400 text-white hover:bg-gray-500 shadow-lg rounded-md mr-2 mb-4"
                              onClick={() => showCancelModal(order?.id)}
                            >
                              {t("Cancel")}
                            </button>
                            <Modal
                              title={t("ConfirmCancel")}
                              visible={isModalVisible}
                              onOk={handleCancelConfirm}
                              onCancel={handleCancelClose}
                              okText={t("Confirm")}
                              cancelText={t("Cancel")}
                            >
                              <p>{t("DeleteOrder")}</p>
                            </Modal>
                          </>
                        )}
                        {/* {getStatusLabel(order?.status) === t("PENDING") && (
                          <button
                            className="px-4 py-2 bg-red-400 text-white hover:bg-gray-500 shadow-lg rounded-md mr-2 mb-4"
                            onClick={() => handleEdit(order)}
                          >
                            {t("Edit")}
                          </button>
                        )} */}
                        <p className="text-base">
                          {t("Total amount")} :
                          <span className="text-[#f36406f8] text-lg font-bold ml-2">
                            {formatMoney(parseFloat(order?.total_amount))} VND
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex justify-center mt-4">
                  <Pagination
                    current={currentPage}
                    total={orderHistory.length}
                    pageSize={itemsPerPage}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
