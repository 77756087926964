import React from "react";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Button } from "antd";
// import { ClockCircleOutlined } from "@ant-design/icons";

const SizeButton = ({
  time,
  attributes,
  selectedSize,
  setSelectedSize,
  product,
  setSelectedID,
  selectedID,
  setProduct,
  setAttribute,
}) => {
  const handleButtonClick = (attribute) => {
    setAttribute(attribute);
    setSelectedSize(attribute.id);
    setSelectedID(product.id);
    setProduct(product);
  };
  
  const filteredData = attributes?.filter((item) => item.product_price !== 0);
  
  const { t } = useTranslation();
  // const text = (
  //   <div className="bg-[#F9E8DC] border-[#F9E8DC] p-4">
  //     <span className="text-black">{t("Pre-order")}</span>
  //     <div className="border-2 text-zinc-500 flex items-center justify-center gap-4 rounded-lg mt-4">
  //       <span className="text-black">{time}</span>
  //       <ClockCircleOutlined className="text-black" />
  //     </div>
  //   </div>
  // );
  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {
          Button: {
            defaultBorderColor: "#FFFFFF",
            defaultHoverBorderColor: "#FFFFFF",
            defaultActiveColor: "#000000",
            defaultActiveBorderColor: "#FFFFFF",
            textHoverBg: "#000000",
            contentFontSize: 15,
            defaultColor: "#000000",
            defaultHoverColor: "#000000",
          },
        },
      }}
    >
      <div className="flex gap-2 lg:gap-4">
        {filteredData?.map((attribute) => (
          <div
            key={attribute.product_size}
            className={`border-2   ${
              selectedSize === attribute.id
                ? "relative border-solid border-1 border-[#779CFE] bg-[#E2EAFF] rounded-lg"
                : "relative border-solid border-1 border-[#CFD1DA] bg-white rounded-lg"
            }`}
          >
            {/* <Button size="middle" >
              {attribute.product_size}
            </Button> */}

            <div
                  className={`size-8  rounded-md flex justify-center items-center shadow-md cursor-pointer ${selectedSize === attribute.id ? "bg-[#E2EAFF]" : "bg-[#F0F0F0]"}`}
                  onClick={() => handleButtonClick(attribute)}
                >
                  <p className="font-bold text-lg">{attribute.product_size}</p>
                </div>
          </div>
        ))}
      </div>
    </ConfigProvider>
  );
};

export default SizeButton;
