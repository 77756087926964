import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../../assets/images/defaultImage.jpg";
import Event from "../menu/event";
import { listStore } from "../../../actions/storeAction";
import { motion } from "framer-motion";

const Store = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storeList = useSelector((state) => state.storeList?.storeList);
  useEffect(() => {
    window.scroll(0, 0);
    const params = {
      page: "",
    };
    dispatch(listStore(params));
  }, []);
  return (
    <div className="grid items-center mx-auto  ">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
        className="md:mt-16 mt-6 lg:px-40 grid items-center grid-cols-1 lg:grid-cols-2 gap-10"
      >
        {storeList &&
          storeList?.map((el, index) => (
            <Event
              image={el?.image ? el?.image : defaultImg}
              size={350}
              IsStore={true}
              location={el?.address}
              openingTime={el?.openingTime}
              closeTime={el?.closeTime}
            />
          ))}
      </motion.div>
    </div>
  );
};

export default Store;
