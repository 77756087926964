import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleCheck,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { Alert } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { motion } from "framer-motion";
import { resetPassword } from "../../../actions/userAction";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const password = watch("password");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ErrorNotice, setErrorNotice] = useState(false);
  const [SuccessNotice, setSuccessNotice] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { success, error } = useSelector((state) => state.userResetPassword);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);

  const onSubmit = async (data) => {
    const { password } = data;
    const { token } = params;
    dispatch(resetPassword(password, token));
    setRegistrationCompleted(true);
  };

  useEffect(() => {
    if (registrationCompleted) {
      if (success === true) {
        setSuccessNotice(true);
        setErrorNotice(false);
        setTimeout(() => navigate("/login"), 1000);
      } else if (success === false) {
        setErrorNotice(true);
        setSuccessNotice(false);
      }
    }
  }, [success, registrationCompleted, navigate]);

  useEffect(() => {
    if (SuccessNotice || ErrorNotice) {
      const timeout = setTimeout(() => {
        setSuccessNotice(false);
        setErrorNotice(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [SuccessNotice, ErrorNotice]);

  return (
    <div className="w-full h-dvh bg-gradient-to-r from-[#c97e6f] to-[#d55f94] flex justify-center items-start flex-grow">
      <div className="absolute z-50">
        {SuccessNotice && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-10 right-2 md:right-10"
          >
            <Alert
              message={<span><CheckCircleOutlined style={{ color: "Green" }} /> {t("Success")}</span>}
              description={t(`Your new password has been updated successfully`)}
              type="success"
              closable
              onClose={() => setSuccessNotice(false)}
            />
          </motion.div>
        )}
        {ErrorNotice && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-10 right-2 md:right-10"
          >
            <Alert
              message={<span><ExclamationCircleOutlined style={{ color: "red" }} /> {t("Error")}</span>}
              description={t("Your reset link is invalid")}
              type="error"
              closable
              onClose={() => setErrorNotice(false)}
            />
          </motion.div>
        )}
      </div>
      <div className="flex pt-28 md:w-1/2 w-full justify-center items-center h-full pb-2">
        <form className="w-4/6 bg-white shadow-md rounded px-8 pt-6 pb-8" onSubmit={handleSubmit(onSubmit)}>
          {/* Password */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("Password")}
              <div className="relative">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: true,
                    minLength: 6,
                    maxLength: 64,
                    validate: (value) => !value.includes(" "),
                  })}
                />
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                  {showPassword ? (
                    <EyeInvisibleOutlined onClick={toggleShowPassword} style={{ cursor: "pointer" }} />
                  ) : (
                    <EyeOutlined onClick={toggleShowPassword} style={{ cursor: "pointer" }} />
                  )}
                </div>
              </div>
            </label>
            {isSubmitted && errors.password && (
              <div className="text-red-500">
                <FontAwesomeIcon icon={faTimesCircle} />
                <span className="ml-2 italic text-sm">
                  {t("Input from 6 to 64 character and no blank space")}
                </span>
              </div>
            )}
          </div>
          {/* Confirm password */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("ConfirmPassword")}
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("confirmPassword", {
                    required: true,
                    validate: (value) => value === password,
                  })}
                />
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                  {showConfirmPassword ? (
                    <EyeInvisibleOutlined onClick={toggleShowConfirmPassword} style={{ cursor: "pointer" }} />
                  ) : (
                    <EyeOutlined onClick={toggleShowConfirmPassword} style={{ cursor: "pointer" }} />
                  )}
                </div>
              </div>
            </label>
            {isSubmitted && errors.confirmPassword && (
              <div className="text-red-500">
                <FontAwesomeIcon icon={faTimesCircle} />
                <span className="ml-2 italic text-sm">
                  {t("Retype password match")}
                </span>
              </div>
            )}
          </div>
          {/* Button submit */}
          <div className="flex justify-center items-center">
            <div className="w-full md:w-2/3">
              <button
                className="w-full shadow bg-gradient-to-r from-[#db6c56] to-[#bb457a] hover:bg-[#1890FF] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
                type="submit"
              >
                {t("Reset")}
              </button>
            </div>
          </div>
          <div className="mt-6 flex justify-center items-center">
            <Link to="/login">
              <span className="text-md ml-2 font-bold text-[#1890FF] cursor-pointer">
                {t("Login")}
              </span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
