import axios from "axios";
export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST";
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAIL = "ORDER_DETAIL_FAIL";

axios.defaults.baseURL = process.env.BASE_URL;

const OrderDetail = async (token, id) => {
    try {
        
        const headers = {
            Authorization: `Bearer ${token}`    
        }
        const response = await axios.get(`/api/v1/user/order/${id}`, {
            headers: headers
        });  
        return response.data;
    } catch (error) {
        
        console.error("Error checking order history:", error);
        throw error;
    }

};

// Action Creator for fetching shipping fee
export const fetchOrderDetailAction = (token, id) => async (dispatch) => {
  dispatch({ type: ORDER_DETAIL_REQUEST });
  try {
    const objectResult = await OrderDetail(token, id);
    dispatch({
      type: ORDER_DETAIL_SUCCESS,
      payload: objectResult?.data?.order,
    });
    localStorage.setItem("orderDetail", JSON.stringify(objectResult?.data?.order));
  } catch (error) {
    dispatch({
      type: ORDER_DETAIL_FAIL,
      payload: error.response
        ? error.response.data
        : "Failed to fetch order detail",
    });
  }
};
