import axios from "axios";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_RESET,
  PRODUCT_REQUEST,
  PRODUCT_FAIL,
  PRODUCT_SUCCESS,
} from "../constants/productConstant";

axios.defaults.baseURL = process.env.BASE_URL;

export const listProduct = (query) => async (dispatch, getState) => {
  try {
    const { page, pageSize, sort, order, keyword, category_id, tag_id } = query;

   
    const dsort = sort ? sort : "";
    const dorder = order ? order : "";
    const dkeyword = keyword ? keyword : "";
    const dcategory_id = category_id ? category_id : "";
    const dtag_id = tag_id ? tag_id : "";
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });

    // const {
    //   productList: { productList },
    // } = getState();

    const params = {
      sort: dsort,
      order: dorder,
      keyword: dkeyword,
      category_id: dcategory_id,
      tag_id: dtag_id,
    };
    const { data } = await axios.get("/api/v1/user/product/list", {
      params: params,
    });

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//get product details
export const productDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_REQUEST });

    //api call to get product
    const { data } = await axios.get(`/api/v1/user/product/${id}`);
    dispatch({
      type: PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
