import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { defaultImage } from "../../../assets/images";
import { Pagination, Button } from "antd";
import { listCartProduct } from "../../../actions/cartAction";
import { formatMoney } from "../../../utils/helpers";
import i18n from "../../../i18n";
const ShoppingCartSummary = ({
  products,
  onRemoveProduct,
  updateTotalPrice,
}) => {
  const initialCounts = products?.reduce((counts, product) => {
    counts[product.IdUI] = product.quantity;
    return counts;
  }, {});

  const [counts, setCounts] = useState(initialCounts);
  const backgroundColors = ["bg-[#eceab5]", "bg-[#adcddc]"];

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 3;

  const totalPages = Math.ceil(products?.length / productsPerPage);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const dispatch = useDispatch();
  const handleIncreaseCount = (productId) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: (prevCounts[productId] || 1) + 1,
    }));
    const newCartProducts = products.map((product) => {
      if (product.IdUI === productId) {
        return { ...product, quantity: product.quantity + 1 };
      }
      return product;
    });
    localStorage.setItem("cartProducts", JSON.stringify(newCartProducts));
    dispatch(listCartProduct());
  };

  const handleDecreaseCount = (productId) => {
    if (counts[productId] && counts[productId] > 1) {
      setCounts((prevCounts) => ({
        ...prevCounts,
        [productId]: prevCounts[productId] - 1,
      }));
      const newCartProducts = products?.map((product) => {
        if (product.IdUI === productId) {
          return { ...product, quantity: product.quantity - 1 };
        }
        return product;
      });
      localStorage.setItem("cartProducts", JSON.stringify(newCartProducts));
      dispatch(listCartProduct());
    }
  };

  useEffect(() => {
    let totalPrice = 0;
    if (products?.length > 0) {
      totalPrice = products?.reduce(
        (total, product) =>
          total + product.price * (counts[product.IdUI] || product.quantity),
        0
      );
    }
    updateTotalPrice(totalPrice);
  }, [products, counts, updateTotalPrice]);

  const handleRemoveProduct = (productId) => {
    onRemoveProduct(productId);

    const updatedProducts = products.filter(
      (product) => product.IdUI !== productId
    );

    const newTotalPages = Math.ceil(updatedProducts.length / productsPerPage);

    if (updatedProducts.length === 0 || currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }

    if (currentPage === totalPages && currentPage > newTotalPages) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [languageChange, setLanguageChange] = useState(i18n.language);
  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);
  return (
    <div className="shopping-cart-summary relative font-roboto ">
      <div className="product-details py-2 bg-[#F2EBE6] px-2 mr-2 ml-2 rounded-xl">
        {currentProducts?.map((product, index) => (
          <div
            key={index}
            className={`product-item mb-4 rounded-xl shadow-md text-center items-center grid grid-cols-2 md:grid-cols-6 md:ml-10 md:mr-10 pt-1 pb-1 border-b font-bold border-gray-200 
            ${backgroundColors[index % backgroundColors?.length]}`}
          >
            {/**image */}
            <div className=" product-info flex justify-center items-center h-full ">
              <div className="flex flex-row gap-2 items-center justify-center py-2 ">
                <img
                  src={product?.image ? product?.image : defaultImage}
                  alt={
                    languageChange === "en" ? product?.name_en : product?.name
                  }
                  className=" size-28 object-fit rounded-xl shadow-md "
                />
                <div>
                  <Button
                    onClick={() => handleRemoveProduct(product?.IdUI)}
                    className="bg-[#e44747] mt-2 mb-4 h-8 leading-3 text-white text-lg font-semibold rounded-xl shadow-md transition-colors duration-300 ease-in-out"
                  >
                    {t("Delete")}
                  </Button>
                </div>
              </div>
            </div>

            {/**Size and name */}
            <div className=" flex flex-col items-center justify-center text-base">
              <span className="text-sm lg:text-lg">
                {" "}
                {languageChange === "en"
                  ? product?.name_en
                  : product?.name}{" "}
              </span>
              <div className="text-sm lg:text-md">
                {product?.type === 1 && (
                  <span className="bg-[#5f76cb] p-1 ml-2 mt-3  w-8  text-white text-lg font-semibold rounded-xl shadow-md transition-colors duration-300 ease-in-out">
                    {t("Cold")}
                  </span>
                )}
                {product?.type === 2 && (
                  <span className="bg-[#9a302e] p-1 ml-2 mt-3  w-8  text-white text-lg font-semibold rounded-xl shadow-md transition-colors duration-300 ease-in-out">
                    {t("Hot")}
                  </span>
                )}
              </div>
              <div className="text-md flex">
                <span className="text-neutral-300 mt-4">Size: </span>

                <span className="bg-[#e44747] ml-2 mt-3  w-8  text-white text-lg font-semibold rounded-xl shadow-md transition-colors duration-300 ease-in-out">
                  {product?.size}
                </span>
              </div>
            </div>
            {/**category */}
            <div className="flex items-center justify-center">
              <span className="text-sm lg:text-base">
                {" "}
                {languageChange === "en"
                  ? product?.category_en
                  : product?.category}
              </span>
            </div>

            {/** inc button */}
            <div className="flex justify-center mb-2 rounded-3xl">
              <button
                onClick={() => handleDecreaseCount(product?.IdUI)}
                className="p-2 bg-red-400 hover:bg-red-200 rounded-l-lg text-white block w-8"
              >
                -
              </button>
              <input
                type="text"
                className="w-10 bg-red-400 border-2 border-red-700 text-center text-white"
                // value={counts[product.id] || 1}
                value={product?.quantity}
                readOnly
              />

              <button
                onClick={() => handleIncreaseCount(product?.IdUI)}
                className="p-2 bg-red-400 hover:bg-red-200 rounded-r-lg text-white block w-8"
              >
                +
              </button>
            </div>
            {/** base price */}
            <span className=" text right md:text-center mb-2 text-lg font-semibold ">
              {formatMoney(product?.price)} VND
            </span>
            {/** total price */}
            <span className="text-center mb-2  text-lg font-semibold">
              {formatMoney(
                product?.price * (counts[product?.IdUI] || 1)
              )}{" "}
              VND
            </span>
          </div>
        ))}
      </div>
      {/**pagination */}
      <div className="flex justify-center md:justify-start mb-4 md:mr-20">
        <Pagination
          current={currentPage}
          total={products?.length}
          pageSize={productsPerPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ShoppingCartSummary;
