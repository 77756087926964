import React from "react";

import { CheckOutlined } from "@ant-design/icons";


const ProcessBar = ({ currentStep, steps }) => {
  return (
    <div className=" md:w-full mt-4 md:mt-0 pt-4 md:p-0 bg-white h-36 md:h-[90%] rounded-xl">
      {/* Process Stepper Bar */}
      <div className="flex justify-center items-center h-full">
        {steps?.map((step, i) => (
          <div
            key={i}
            className={`step-item ${currentStep === i + 1 && "active"} ${
              i + 1 < currentStep && "complete"
            } `}
          >
            <div className="step">
              {i + 1 < currentStep ? (
                <CheckOutlined
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                />
              ) : (
                i + 1
              )}
            </div>
            <p className="text-black font-semibold mt-1 p-1 md:p-0 h-20  md:h-auto text-center text-sm ">
              {step}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessBar;
