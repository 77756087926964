import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { Pagination } from "antd";

// import SizeButton from "./side-button";
import {  formatMoney } from "../../../utils/helpers";
import Item from "../detail-product/item/Item";
import "./menu.css";
import i18n from "../../../i18n";

const BreadCard = ({ data, review }) => {
  const [current, setCurrent] = useState(1);
  const pageSize = 12;
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = data?.slice(startIndex, endIndex);
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [attribute, setAttribute] = useState(null);
  // const [product, setProduct] = useState(null);
  // const [selectedSize, setSelectedSize] = useState("");
  // const [selectedID, setSelectedID] = useState("");
  // const reviewProductData = useSelector((state) => state.userReviewList);
  const [languageChange, setLanguageChange] = useState(i18n.language);
  const [ratingList, setRatingList] = useState([]);

  const onChange = (page) => {
    setCurrent(page);
    window.scroll(0, 0);
  };
  const setPriceRange = (attributes) => {
    if (!attributes || attributes?.length === 0) {
      return { price: `0,000 - 0,000` };
    }

    if (attributes?.length === 1) {
      return {
        price: attributes[0].product_price,
      };
    }
    if (attributes?.length === 1 && attributes[0]?.product_price === 0) {
      // return { price: "" };
      return;
    }
    // attributes?.sort((a, b) => a.product_price - b.product_price);
    attributes = attributes
      ?.filter((item) => item.product_price !== 0)
      .sort((a, b) => a.product_price - b.product_price);
    return {
      price: `${formatMoney(attributes[0]?.product_price)} - ${formatMoney(
        attributes[attributes?.length - 1]?.product_price
      )}`,
    };
  };
  const getPrice = (attributes, selectedSize) => {
    const selectedAttribute = attributes.find(
      (attr) => attr.id === selectedSize
    );
    if (selectedAttribute) {
      return {
        price: `${formatMoney(selectedAttribute.product_price)}`,
      };
    }
  };

  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    setRatingList(calculate(review));
  }, [review]);

  const calculate = (data) => {
    const averageRatings = {};
    // Duyệt qua mảng reviews
    data?.reviews?.forEach((review) => {
      const { rating, product_id } = review;
      if (rating !== 0) {
        if (!averageRatings[product_id]) {
          averageRatings[product_id] = {
            totalRating: 0,
            count: 0,
          };
        }
        averageRatings[product_id].totalRating += rating;
        averageRatings[product_id].count++;
      }
    });
    const averageRatingsArray = Object.entries(averageRatings).map(
      ([product_id, { totalRating, count }]) => {
        const averageRating = totalRating / count;
        let result = 0;
        const decimalPart = averageRating % 1; // Lấy phần thập phân của giá trị trung bình
        if (decimalPart < 0.3) {
          result = Math.floor(averageRating); // Làm tròn xuống
        } else if (decimalPart === 0.5) {
          result = averageRating; // Giữ nguyên
        } else {
          result = Math.ceil(averageRating); // Làm tròn lên
        }

        return { product_id, result };
      }
    );
    return averageRatingsArray;
  };

  const renderStarNumber = (ratingList, id) => {
    const foundRating = ratingList.find((e) => e.product_id == id);
    const showStar = foundRating ? foundRating.result : 0;
    return showStar;
  };
  return (
    <>
      {currentData?.length !== 0 ? (
        <>
          <div className="md:grid  md:grid-rows-[repeat(auto-fill,minmax(300px,1fr))] md:grid-cols-[repeat(4,minmax(300px,1fr))] grid-cols-1 flex flex-col justify-center items-center gap-2">
            {currentData?.map((el, index) => (
              <div
                key={index}
                className="flex flex-col justify-center border rounded-lg bg-gray-200"
              >
                {/**image of product */}
                <Link to={`/detail/${el.id}`}>
                  <div className="">
                    <Item item={el} isShow={false} />
                  </div>
                </Link>

                <div className="mt-2">
                  {/**product price  & name */}
                  <div className="flex justify-around items-center px-2 md:px-4">
                    {/* Product Star */}
                    <span className="border mr-7 gap-2 h-7 text-white flex items-center justify-center bg-orange-400 text-lg font-bold p-2">
                      <span>{renderStarNumber(ratingList, el.id)}</span>
                      <FaStar size={20} />
                    </span>
                    {/**Button Add to cart */}
                    <div className="md:block  pb-2 flex-wrap">
                      {/* <Link to="/cart"> */}
                      <Link to={`/detail/${el.id}`}>
                        <button
                          className="
                      text-white 
                      flex 
                      items-center 
                      justify-center
                      font-semibold
                      px-4 py-3 
                      rounded-3xl
                      relative overflow-hidden 
                    bg-[#FF4D4F] 
                      shadow-lg 
                      transition-all 
                      before:absolute 
                      before:bottom-0 
                      before:left-0 
                      before:top-0 
                      before:z-0 
                      before:h-full 
                      before:w-0
                    before:bg-red-500 
                      before:transition-all 
                      before:duration-500 
                    hover:text-white 
                    hover:shadow-red-400 
                      hover:before:left-0 
                      hover:before:w-full
                      text-lg"
                        >
                          <span className="relative">{t("View Detail")}</span>
                        </button>
                      </Link>
                    </div>

                    {/* </Link> */}
                  </div>
                  {/**Button to choose size*/}
                  {/* <div className="mt-3 pl-4 w-40">
                  <SizeButton
                    time={el.time}
                    attributes={el.attributes}
                    product={el}
                    setProduct={setProduct}
                    selectedSize={selectedSize}
                    setSelectedSize={setSelectedSize}
                    selectedID={selectedID}
                    setSelectedID={setSelectedID}
                    setAttribute={setAttribute}
                  />
                </div> */}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center pb-6 pt-4">
            <Pagination
              current={current}
              pageSize={pageSize}
              onChange={onChange}
              total={data?.length}
            />
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center">
          <span className=" mt-14 h-dvh text-2xl text-blacks font-semibold">
            {t("No product")}
          </span>
        </div>
      )}
    </>
  );
};

export default BreadCard;
