import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { defaultImage } from "../../../assets/images";
import "../checkout/checkout.css";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSnowflake } from "@fortawesome/free-regular-svg-icons";
import { faFireBurner } from "@fortawesome/free-solid-svg-icons";
import { renderStarFromNumber, formatMoney } from "../../../utils/helpers";

import {
  CheckOutlined,
  ShoppingCartOutlined,
  PlusOutlined,
  MinusOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Alert } from "antd";

import { motion } from "framer-motion";
import { addToCart } from "../../../actions/addToCartAction";
import { productDetail } from "../../../actions/productAction";
import { listProduct } from "../../../actions/productAction";
import Item from "../detail-product/item/Item";
import SizeButton from "../menu/side-button";
import Review from "./review";
import { reviewProduct } from "../../../actions/reviewAction";

const ProductItem = () => {
  localStorage.removeItem("guestUserInfo");
  const [currentMethod, setCurrentMethod] = useState(1);
  // const [isFavorite, setIsFavorite] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [SuccessNotice, setSuccessNotice] = useState(false);
  const [message, setMessage] = useState("");
  const [ErrorNotice, setErrorNotice] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const { productId } = params;
  const product = useSelector((state) => state.productDetail?.product?.product);
  const reviewProductData = useSelector(
    (state) => state.userReviewProduct?.reviewProductList
  );
  const [ratingScore, setRatingScore] = useState(0);
  const RelatedProductList = useSelector(
    (state) => state.productList?.productList
  );

  const [relatedProducts, setRelatedProduct] = useState([]);
  const [productdetail, setProductdetail] = useState(null);
  const [attribute, setAttribute] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedID, setSelectedID] = useState("");
  const [productType, setProductType] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(productDetail(productId));
    dispatch(reviewProduct(productId));
    window.scroll(0, 0);
  }, [productId, update, ratingScore, rerender]);

  useEffect(() => {
    if (reviewProductData) {
      setRatingScore(calculateAverageRating(reviewProductData?.reviews));
    }
  }, [productId, update, rerender, reviewProductData]);

  useEffect(() => {
    const params = {
      category_id: product?.category_id,
    };
    dispatch(listProduct(params));
  }, [productId, product]);
  useEffect(() => {
    const list = RelatedProductList?.filter((el) => el.id != productId);
    setRelatedProduct(list);
  }, [RelatedProductList]);

  const [languageChange, setLanguageChange] = useState(i18n.language);
  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    if (SuccessNotice || ErrorNotice) {
      const timeout = setTimeout(() => {
        setSuccessNotice(false);
        setErrorNotice(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [SuccessNotice, ErrorNotice]);

  const selectMethod1 = () => {
    setProductType(1);
    if (currentMethod !== 1) {
      setCurrentMethod(1);
    }
  };

  const selectMethod2 = () => {
    setProductType(2);
    if (currentMethod !== 2) {
      setCurrentMethod(2);
    }
  };
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const setPriceRange = (attributes) => {
    if (!attributes || attributes?.length === 0) {
      return { price: `0,000 - 0,000` };
    }

    if (attributes?.length === 1) {
      return {
        price: attributes[0].product_price,
      };
    }
    if (attributes?.length === 1 && attributes[0]?.product_price === 0) {
      // return { price: "" };
      return;
    }
    // attributes?.sort((a, b) => a.product_price - b.product_price);
    attributes = attributes
      ?.filter((item) => item.product_price !== 0)
      .sort((a, b) => a.product_price - b.product_price);
    return {
      price: `${formatMoney(attributes[0]?.product_price)} - ${formatMoney(
        attributes[attributes?.length - 1]?.product_price
      )}`,
    };
  };
  const getPrice = (attributes, selectedSize) => {
    const selectedAttribute = attributes?.find(
      (attr) => attr.id === selectedSize
    );
    if (selectedAttribute) {
      return {
        price: `${formatMoney(selectedAttribute?.product_price)}`,
      };
    }
  };
  function getNewCartUIId() {
    const lastId = parseInt(localStorage.getItem("lastCartIdUI")) || 0;
    const newId = lastId + 1;
    localStorage.setItem("lastCartIdUI", newId);
    return newId;
  }
  const handleAddToCart = () => {
    if (selectedSize === "") {
      setMessage(t("Must choose size to add to cart"));
      setErrorNotice(true);
      setSuccessNotice(false);
      return;
    } else {
      const IdUI = getNewCartUIId();
      const newItem = {
        IdUI,
        id: product?.id,
        name: product?.name,
        name_en: product?.name_en,
        quantity: quantity,
        attribute_id: selectedSize,
        price: attribute?.product_price,
        size: attribute?.product_size,
        category: product?.category?.name,
        category_en: product?.category?.name_en,
        image: product?.image,
        type: productType,
      };
      const cartProducts = localStorage.getItem("cartProducts");
      let updatedCartItems = [];
      if (cartProducts) {
        // Parse the existing cart items from localStorage
        const existingCartItems = JSON.parse(cartProducts);

        // Check if the item already exists in the cart
        const existingItem = existingCartItems.find(
          (item) =>
            item.id === newItem.id &&
            item.attribute_id === newItem.attribute_id &&
            item.type === newItem.type
        );

        if (existingItem) {
          // If the item exists, increase the quantity
          existingItem.quantity += quantity;
          updatedCartItems = [...existingCartItems];
        } else {
          // If the item does not exist, add it to the cart
          updatedCartItems = [...existingCartItems, newItem];
        }
      } else {
        // If there are no existing cart items, add the new item to the cart
        updatedCartItems = [newItem];
      }
      // Update the cart items in localStorage
      localStorage.setItem("cartProducts", JSON.stringify(updatedCartItems));
      dispatch(addToCart(updatedCartItems));
      localStorage.setItem("cartCount", updatedCartItems?.length);
      window.dispatchEvent(
        new CustomEvent("cartUpdated", { detail: updatedCartItems?.length })
      );
      setMessage(t("Add Success"));
      setErrorNotice(false);
      setSuccessNotice(true);
      setQuantity(1);
    }
  };

  const calculateAverageRating = (reviews) => {
    if (reviews?.length === 0) {
      return 0;
    } else {
      let total = 0;
      const sum = reviews?.reduce((acc, review) => {
        if (review?.rating > 0) {
          total += 1;
          return acc + review?.rating;
        } else {
          return acc;
        }
      }, 0);

      if (total !== 0) {
        const average = sum / total;
        const decimalPart = average % 1; // Lấy phần thập phân của giá trị trung bình
        if (decimalPart < 0.3) {
          return Math.floor(average); // Làm tròn xuống
        } else if (decimalPart === 0.5) {
          return average; // Giữ nguyên
        } else {
          return Math.ceil(average); // Làm tròn lên
        }
      }

      return 0;
    }
  };
  return (
    <div className="w-full h-full bg-[#F2EBE6] lg:pt-10">
      <div className="flex  bg-[#F8F8F8] text-black shadow-md mt-12 md:mt-24 p-4 px-4 md:px-44">
        <Link to="/">
          <p className="text-xl text-black font-normal ">{t("Home")}</p>
        </Link>
        <RightOutlined className="text-sm mx-3" />

        <Link to="/menu">
          <p className="text-xl text-black ">{t("Menu")}</p>
        </Link>
        <RightOutlined className="text-sm mx-3" />

        <p className="text-xl text-black font-bold ">{t("Detail")}</p>
      </div>
      {/* Review Product + Order Product */}
      <div className="absolute z-60">
        {SuccessNotice && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-32 right-2 md:right-10 z-40 "
          >
            <Alert
              message={
                <span>
                  <CheckCircleOutlined style={{ color: "green" }} />{" "}
                  {t("Success")}{" "}
                </span>
              }
              description={message}
              type="success"
              closable
              onClose={() => setSuccessNotice(false)}
            />
          </motion.div>
        )}
        {ErrorNotice && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-32 right-2 md:right-10 z-40"
          >
            <Alert
              message={
                <span>
                  <ExclamationCircleOutlined style={{ color: "red" }} />{" "}
                  {t("Error")}{" "}
                </span>
              }
              description={message}
              type="error"
              closable
              onClose={() => setErrorNotice(false)}
            />
          </motion.div>
        )}
      </div>
      <div className="w-full  px-8 lg:px-44 pt-6 py-6 flex flex-col gap-6">
        {/*Image  Product */}
        <div className="w-full  h-auto  md:h-[500px] flex flex-col md:flex-row ">
          <div className="w-full md:w-1/2  md:h-full flex  flex-col bg-[#F2EBE6] pb-6 md:pb-0 md:px-6 xl:px-16">
            <img
              src={product?.image? product?.image : defaultImage}
              alt={languageChange === "en" ? product?.name_en : product?.name}
              className="w-full h-full md:h-2/3 rounded-2xl shadow-xl object-fit"
            />
            <div className="w-full  h-1/3 pt-4 hidden md:flex justify-between items-center">
              <img
                src={product?.image? product?.image : defaultImage}
                alt={languageChange === "en" ? product?.name_en : product?.name}
                className="w-[31%] h-full rounded-2xl shadow-xl"
              />
              <img
                src={product?.image? product?.image : defaultImage}
                alt={languageChange === "en" ? product?.name_en : product?.name}
                className="w-[31%] h-full rounded-2xl shadow-xl"
              />
              <img
                src={product?.image? product?.image : defaultImage}
                alt={languageChange === "en" ? product?.name_en : product?.name}
                className="w-[31%] h-full rounded-2xl shadow-xl"
              />
            </div>
          </div>

          {/* Order Product */}
          <div className="w-full md:w-1/2  md:h-full bg-white rounded-xl shadow-2xl py-4 px-8 ">
            <div className="flex justify-between">
              <h1 className="text-xl xl:text-3xl font-AlfaSlabOne pr-2">
                {languageChange === "en" ? product?.name_en : product?.name}
              </h1>
            </div>

            <span className="font-AlfaSlabOne text-2xl my-4 text-balance">
              <span className="text-xl flex font-semibold pt-4 pb-2">
                {selectedSize
                  ? getPrice(product?.attributes, selectedSize)?.price
                  : setPriceRange(product?.attributes)?.price}
              </span>
              <span className="flex text-xl pt-4 pb-3">
                {renderStarFromNumber(ratingScore, 24)}
              </span>
            </span>

            {/* Product method */}
            <div className="w-full py-4">
              {/* Cold Product */}

              <div className="flex justify-center p-2  ">
                <div
                  className={`  flex flex-col w-32 items-center rounded-xl shadow-md  cursor-pointer ${
                    currentMethod === 1 && "method-active"
                  } ${currentMethod !== 1 && "method-inactive"}`}
                  onClick={selectMethod1}
                >
                  <FontAwesomeIcon icon={faSnowflake} className="size-8 pt-2" />
                  <p className="p-4 text-black font-semibold text-md ">
                    {t("Cold")}
                  </p>
                  <span
                    className={`hidden justify-center items-center absolute w-8 h-8 bg-[#779CFE] rounded-full top-0 right-0 translate-x-2 -translate-y-2 ${
                      currentMethod === 1 && "icon-active"
                    }`}
                    id="icon"
                  >
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    />
                  </span>
                </div>
                {/* Hot Product*/}
                <div
                  className={`ml-4  flex flex-col w-32 items-center rounded-xl shadow-md  cursor-pointer ${
                    currentMethod === 2 && "method-active"
                  } ${currentMethod !== 2 && "method-inactive"}`}
                  onClick={selectMethod2}
                >
                  <FontAwesomeIcon
                    icon={faFireBurner}
                    className="size-8 pt-2"
                  />
                  <p className="p-4  text-black font-semibold text-md ">
                    {t("Hot")}
                  </p>
                  <span
                    className={`hidden justify-center items-center absolute w-8 h-8 bg-[#779CFE] rounded-full top-0 right-0 translate-x-2 -translate-y-2 ${
                      currentMethod === 2 && "icon-active"
                    }`}
                    id="icon"
                  >
                    <CheckOutlined
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="pt-2 pb-6 mt-4">
              <SizeButton
                // time={el.time}
                attributes={product?.attributes}
                product={product}
                setProduct={setProductdetail}
                selectedSize={selectedSize}
                setSelectedSize={setSelectedSize}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                setAttribute={setAttribute}
              />
            </div>

            {/* Add To cart product  */}
            <div className="w-full flex gap-2 lg:gap-4 mt-4">
              <button
                onClick={handleAddToCart}
                className="bg-[#AA553B] hover:bg-red-300 w-44 h-12 text-white md:text-lg font-semibold rounded-xl shadow-md transition-colors duration-300 ease-in-out"
              >
                {t("Add to cart")}
                <ShoppingCartOutlined className="md:text-lg font-semibold pl-2" />
              </button>

              <div className="bg-[#F0F0F0] w-32  h-12 rounded-xl flex border-2 border-[#F0F0F0]">
                <div className=" w-1/3  h-full flex justify-center border border-white border-t-0 border-l-0 border-r-1 border-b-0 cursor-pointer">
                  <button className="hover:bg-[#E2EAFF] w-full h-full rounded-l-xl">
                    <MinusOutlined
                      onClick={handleDecrease}
                      className=" font-bold text-xl"
                    />
                  </button>
                </div>

                <input
                  type="text"
                  className="w-1/3 h-full bg-white text-black font-semibold text-center"
                  value={quantity}
                  readOnly
                />

                <div className="w-1/3 h-full flex justify-center border border-white border-t-0 border-l-1 border-r-0 border-b-0 cursor-pointer">
                  <button className="hover:bg-[#E2EAFF] w-full h-full rounded-r-xl">
                    <PlusOutlined
                      onClick={handleIncrease}
                      className=" font-bold text-xl"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Description */}
        {/* <div className="w-full flex flex-col  min-h-96 bg-white rounded-xl shadow-2xl px-8 py-8">
          <p className="text-2xl font-AlfaSlabOne  ">
            {t("Description")}
          </p>
          <p className="mt-4 text-balance text-base font-medium  ">
            {languageChange === "vi"
              ? product?.description
              : product?.description_en}
          </p>
        </div> */}
        {/* <div className="w-full min-h-96 bg-white rounded-xl shadow-2xl px-8 py-4">
          <p className="text-2xl font-AlfaSlabOne  ">Description</p>
          <p className="mt-4 text-base font-medium pr-6  break-all whitespace-normal">
            
            {languageChange === "vi"
              ? product?.description
              : product?.description_en}
          
          </p>
        </div> */}
        <div className="w-full  bg-white rounded-xl shadow-2xl px-8 py-4 overflow-hidden">
          <p className="text-2xl font-AlfaSlabOne">{t('Description')}</p>
          <p
            className={`mt-4 text-base font-medium pr-6 ${
              showFullDescription ? "whitespace-pre-line" : "whitespace-pre-line"
            }`}
          >
            {languageChange === "vi"
              ? showFullDescription
                ? product?.description
                : `${product?.description?.slice(0, 300)}...`
              : showFullDescription
              ? product?.description_en
              : `${product?.description_en?.slice(0, 300)}...`}
          </p>
          <div className="flex justify-center">
            <button className="mt-2 text-blue-500 hover:text-gray-500 underline" onClick={toggleDescription}>
              {showFullDescription ? "Show less" : "Show more"}
            </button>
          </div>
        </div>
        {/* Related Product */}
        <div className="w-full h-auto flex flex-col bg-white px-8 rounded-xl shadow-2xl  py-4">
          <h2 className="text-2xl font-AlfaSlabOne ">{t("Related product")}</h2>
          <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:flex-row justify-between gap-4 pt-2">
            {relatedProducts?.length > 0 ? (
              relatedProducts?.map((el, index) => (
                <Link key={index} to={`/detail/${el.id}`}>
                  <Item item={el} />
                </Link>
              ))
            ) : (
              <span className="text-lg font-bold ">
                {t("There are no products")}{" "}
              </span>
            )}
          </div>
        </div>
        {/* Comment */}
        <div className="w-full min-h-96 bg-white rounded-xl shadow-2xl ">
          <p className="text-2xl font-AlfaSlabOne px-8 py-4 "> {t("Review")}</p>
          <div className="p -2">
            <Review
              productID={productId}
              rerender={rerender}
              setRerender={setRerender}
              ratingScore={ratingScore}
              data={reviewProductData}
            />
          </div>

          {/* <div className="p-2">
            <Comment data={reviewProductData} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
