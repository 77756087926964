import React, { useState, useEffect, useRef } from "react";
import { useSSR, useTranslation } from "react-i18next";
import { CheckOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { ExclamationCircleOutlined, ShopOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProcessBar from "./components/processBar";
import ProductChecking from "./components/productChecking";
import "./checkout.css";
import {
  deliveryIcon,
  moneyIcon,
  qrCode,
  store,
} from "../../../assets/images/index";
import { useDispatch, useSelector } from "react-redux";
import { checkoutAction } from "../../../actions/checkoutAction";
import { updateOrderAction } from "../../../actions/updateOrderAction";
import { addToCart } from "../../../actions/addToCartAction";
import { checkExistenceProductList } from "../../../services/product/productService";
import { fetchShippingFee } from "../../../actions/shippingFeeAction";
import { Modal, Input } from "antd";
import { formatMoney } from "../../../utils/helpers";
import FormModal from "../modal/form-modal";
import { vietQR, napas, pieCode } from "../../../assets/images/index";
import {
  checkOrderHistory,
  UpdateOrderStatus,
} from "../../../services/order/orderService";
import { fetchOrderDetailAction } from "../../../actions/viewOrderAction";
const Checkout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shippingFee = useSelector((state) => state.shippingFee.Fee);
  const userLogin = useSelector((state) => state.userLogin);
  const guestUserInfo = localStorage.getItem("guestUserInfo")
    ? JSON.parse(localStorage.getItem("guestUserInfo"))
    : null;
  const cartProducts = useSelector((state) => state.cartProductList.products);

  const [newAddressError, setNewAddressError] = useState("");
  let userInfo = {};
  if (userLogin.userInfo !== null && userLogin.userInfo !== undefined) {
    userInfo = userLogin.userInfo.data;
  } else {
    userInfo = guestUserInfo;
  }
  const steps = [
    t("Check shopping cart"),
    t("Proceed with payment"),
    t("Payment Methods"),
    t("Order status"),
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  let feeShippingStatus = [t("Free"), "10.000 VND"];
  const [shipFeeChanged, setshipFeeChanged] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);
  const [currentMethod, setCurrentMethod] = useState(1);
  const [feeShipping, setFeeShipping] = useState(t("Free"));
  const newAddressRef = useRef("");
  const [showQR, setShowQR] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  // const [paymentOnline, setPaymentOnline] = useState(false);
  const [cashOnDelivery, setCashOnDelivery] = useState(false);
  const [TotalProductPrice, setTotalProductPrice] = useState(0);

  const computeTotalPrice = (cartProducts) => {
    let totalPrice = 0;
    cartProducts?.forEach((product) => {
      totalPrice += product.price * product.quantity;
    });
    return totalPrice;
  };

  const totalProductPrice = computeTotalPrice(cartProducts);

  const [totalAmount, setTotalAmount] = useState(
    computeTotalPrice(cartProducts)
  );
  const [SuccessNotice, setSuccessNotice] = useState(false);
  const [ErrorNotice, setErrorNotice] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [note, setNote] = useState("");
  const [isCheckedCod, setIsCheckedCod] = useState(false);
  const selectPickAtStoreMethod = () => {
    if (currentMethod !== 1) {
      setCurrentMethod(1);
      setFeeShipping(feeShippingStatus[0]);
      setTotalAmount(totalProductPrice);
      setshipFeeChanged(true);
    }
  };
  const selectaHamoveMethod = () => {
    if (currentMethod !== 2) {
      setCurrentMethod(2);

      if (shippingFee !== undefined && shippingFee !== null) {
        setFeeShipping(shippingFee);
        setTotalAmount(getTotalOrderFee(totalProductPrice, shippingFee));
      } else {
        setFeeShipping("Calculating...");
      }
    }
  };

  const handleCashOnDeliveryChange = (event) => {
    const isChecked = event.target.checked;
    setCashOnDelivery(isChecked);
    const shippingFeeNumber = parseInt(shippingFee);
    if (isChecked) {
      setFeeShipping(shippingFeeNumber + 10000);
      setTotalAmount(
        getTotalOrderFee(totalProductPrice, shippingFeeNumber + 10000)
      );
      localStorage.setItem("shippingFee", shippingFeeNumber + 10000);
      setIsCheckedCod(true);
    } else {
      localStorage.setItem("shippingFee", shippingFeeNumber);
      setFeeShipping(shippingFee);
      // setTotalAmount(
      //   getTotalOrderFee(totalProductPrice, shippingFeeNumber)
      // );
      setIsCheckedCod(false);
    }
  };
  // for (const cartProduct of cartProducts) {
  //   setNewAddress(cartProduct?.shipment)
  //   break
  // }
  const showModal = () => {
    setIsModalVisible(true);
  };
  const getTotalOrderFee = (totalProductPrice, shippingFee) => {
    let numericTotalPrice = Number(totalProductPrice);
    let numericShippingFee = Number(shippingFee);
    if (isNaN(numericShippingFee)) {
      numericShippingFee = 0;
    }
    return numericTotalPrice + numericShippingFee;
  };

  //   const handleOk = async () => {
  //     const fetchProductExistence = async () => {
  //         const response = await checkExistenceProductList(cartProducts);
  //         localStorage.setItem(
  //             "existProducts",
  //             JSON.stringify(response?.data?.existProducts)
  //         );
  //         if (response?.data?.deleted === true) {
  //             const cartItems = cartProducts;
  //             const deletedProducts = response?.data?.data?.deletedProducts;
  //             const deletedAttributeProducts =
  //                 response?.data?.data?.deletedAttributeProducts;
  //             handleCartWhenProductsDeleted(
  //                 cartItems,
  //                 deletedProducts,
  //                 deletedAttributeProducts
  //             );
  //         }
  //     };
  //     await fetchProductExistence();
  //     const cartProductFromStorage = localStorage.getItem("cartProducts")
  //         ? JSON.parse(localStorage.getItem("cartProducts"))
  //         : null;
  //     const existProducts = localStorage.getItem("existProducts")
  //         ? JSON.parse(localStorage.getItem("existProducts"))
  //         : null;
  //     if (cartProductFromStorage?.length > 0) {
  //         await updateCartItemsWhenProductsUpdated(
  //             cartProductFromStorage,
  //             existProducts
  //         );
  //         const updatedCartProducts = localStorage.getItem("cartProducts")
  //             ? JSON.parse(localStorage.getItem("cartProducts"))
  //             : null;
  //         let orderId;
  //         for (const updatedCartProduct of updatedCartProducts) {
  //             orderId = updatedCartProduct?.orderId;
  //             break;
  //         }
  //         let transformedCartProducts = updatedCartProducts?.map((product) => ({
  //             product_id: product?.id,
  //             quantity: product?.quantity,
  //             attribute_id: product?.attribute_id,
  //             type: product?.type,
  //         }));
  //         let shipment;
  //         const address = newAddress === "" ? userInfo?.address : newAddress;
  //         if (currentMethod === 1) {
  //             // Check if newAddress exists, if not, use userInfo address
  //             shipment = {
  //                 fee: 0,
  //                 address: "",
  //                 phone: userInfo?.phone,
  //             };
  //         } else {
  //             shipment = {
  //                 fee: shippingFee,
  //                 address: address,
  //                 phone: userInfo?.phone,
  //             };
  //         }
  //         const updatedTotalAmount = getTotalOrderFee(
  //             computeTotalPrice(updatedCartProducts),
  //             feeShipping
  //         );
  //         showModal();
  //         if (userInfo?._id !== undefined) {
  //             if (orderId !== null && orderId !== undefined) {
  //                 dispatch(
  //                     updateOrderAction(
  //                         orderId,
  //                         transformedCartProducts,
  //                         shipment,
  //                         updatedTotalAmount,
  //                         note,
  //                         userInfo?.token
  //                     )
  //                 );
  //                 window.dispatchEvent(
  //                     new CustomEvent("cartUpdated", {
  //                         detail: transformedCartProducts?.length,
  //                     })
  //                 );
  //                 setSuccessNotice(true);
  //                 setErrorNotice(false);
  //                 setTimeout(() => {
  //                     window.location.href = "/menu";
  //                 }, 2000);
  //             } else {
  //                 dispatch(
  //                     checkoutAction(
  //                         userInfo?._id,
  //                         transformedCartProducts,
  //                         shipment,
  //                         updatedTotalAmount,
  //                         note
  //                     )
  //                 );
  //                 window.dispatchEvent(
  //                     new CustomEvent("cartUpdated", {
  //                         detail: transformedCartProducts?.length,
  //                     })
  //                 );
  //                 setSuccessNotice(true);
  //                 setErrorNotice(false);
  //                 setTimeout(() => {
  //                     setShowQR(true);
  //                     setCurrentStep(3);
  //                 }, 2000);
  //                 const cartProductFromStorageAfterCheckout = localStorage.getItem(
  //                     "cartProducts"
  //                 )
  //                     ? JSON.parse(localStorage.getItem("cartProducts"))
  //                     : null;
  //                 const cartProductAfterCheckout = JSON.parse(
  //                     JSON.stringify(cartProductFromStorageAfterCheckout)
  //                 );
  //                 cartProductAfterCheckout.forEach((item) => {
  //                     item.checkedOut = true;
  //                 });
  //                 localStorage.setItem(
  //                     "cartProducts",
  //                     JSON.stringify(cartProductAfterCheckout)
  //                 );
  //                 localStorage.removeItem("cartCount");
  //                 window.dispatchEvent(new CustomEvent("cartUpdated", { detail: 0 }));
  //             }
  //         } else {
  //             dispatch(
  //                 checkoutAction(
  //                     userInfo,
  //                     transformedCartProducts,
  //                     shipment,
  //                     updatedTotalAmount,
  //                     note
  //                 )
  //             );
  //             window.dispatchEvent(
  //                 new CustomEvent("cartUpdated", {
  //                     detail: transformedCartProducts?.length,
  //                 })
  //             );
  //             setSuccessNotice(true);
  //             setErrorNotice(false);
  //             setTimeout(() => {
  //                 setShowQR(true);
  //                 setCurrentStep(3);
  //             }, 2000);
  //             const cartProductFromStorageAfterCheckout = localStorage.getItem(
  //                 "cartProducts"
  //             )
  //                 ? JSON.parse(localStorage.getItem("cartProducts"))
  //                 : null;
  //             const cartProductAfterCheckout = JSON.parse(
  //                 JSON.stringify(cartProductFromStorageAfterCheckout)
  //             );
  //             cartProductAfterCheckout.forEach((item) => {
  //                 item.checkedOut = true;
  //             });
  //             localStorage.setItem(
  //                 "cartProducts",
  //                 JSON.stringify(cartProductAfterCheckout)
  //             );
  //             localStorage.removeItem("cartCount");
  //             window.dispatchEvent(new CustomEvent("cartUpdated", { detail: 0 }));
  //         }
  //     } else {
  //         setSuccessNotice(false);
  //         setErrorNotice(true);
  //         setTimeout(() => {
  //             window.location.href = "/menu";
  //         }, 2000);
  //     }

  //     localStorage.removeItem("cartProducts");
  //     localStorage.removeItem("cartCount");
  //     localStorage.removeItem("lastCartIdUI");
  //     localStorage.removeItem("shippingFee");
  //     localStorage.removeItem("existProducts");

  //     setIsModalVisible(false);
  // };

  const handleOk = async () => {
    const fetchProductExistence = async () => {
      const response = await checkExistenceProductList(cartProducts);
      localStorage.setItem(
        "existProducts",
        JSON.stringify(response?.data?.existProducts)
      );
      if (response?.data?.deleted === true) {
        const cartItems = cartProducts;
        const deletedProducts = response?.data?.data?.deletedProducts;
        const deletedAttributeProducts =
          response?.data?.data?.deletedAttributeProducts;
        handleCartWhenProductsDeleted(
          cartItems,
          deletedProducts,
          deletedAttributeProducts
        );
      }
    };
    await fetchProductExistence();

    const cartProductFromStorage = localStorage.getItem("cartProducts")
      ? JSON.parse(localStorage.getItem("cartProducts"))
      : null;
    const existProducts = localStorage.getItem("existProducts")
      ? JSON.parse(localStorage.getItem("existProducts"))
      : null;

    if (cartProductFromStorage?.length > 0) {
      await updateCartItemsWhenProductsUpdated(
        cartProductFromStorage,
        existProducts
      );

      const updatedCartProducts = localStorage.getItem("cartProducts")
        ? JSON.parse(localStorage.getItem("cartProducts"))
        : null;
      let orderId;
      for (const updatedCartProduct of updatedCartProducts) {
        orderId = updatedCartProduct?.orderId;
        break;
      }
      let transformedCartProducts = updatedCartProducts?.map((product) => ({
        product_id: product?.id,
        quantity: product?.quantity,
        attribute_id: product?.attribute_id,
        type: product?.type,
      }));
      let shipment;
      const address = newAddress === "" ? userInfo?.address : newAddress;
      if (currentMethod === 1) {
        // Check if newAddress exists, if not, use userInfo address

        shipment = {
          fee: 0,
          address: "",
          phone: userInfo?.phone,
        };
      } else {
       
        if (isCheckedCod) {
          shipment = {
            fee: feeShipping,
            address: address,
            phone: userInfo?.phone,
            isCod: 1,
          };
        } else {
          shipment = {
            fee: feeShipping,
            address: address,
            phone: userInfo?.phone,
            isCod: 0,
          };
        }
      }
      const updatedTotalAmount = getTotalOrderFee(
        computeTotalPrice(updatedCartProducts),
        feeShipping
        // shippingFee
      );
      showModal();
      if (userInfo?._id !== undefined) {
        if (orderId !== null && orderId !== undefined) {
          dispatch(
            updateOrderAction(
              orderId,
              transformedCartProducts,
              shipment,
              updatedTotalAmount,
              note,
              userInfo?.token
            )
          );
          window.dispatchEvent(
            new CustomEvent("cartUpdated", {
              detail: transformedCartProducts?.length,
            })
          );
          setSuccessNotice(true);
          setErrorNotice(false);
          setTimeout(() => {
            window.location.href = "/menu";
          }, 2000);
        } else {
          dispatch(
            checkoutAction(
              userInfo?._id,
              transformedCartProducts,
              shipment,
              updatedTotalAmount,
              note
            )
          );
          window.dispatchEvent(
            new CustomEvent("cartUpdated", {
              detail: transformedCartProducts?.length,
            })
          );
          setSuccessNotice(true);
          setErrorNotice(false);
          setTimeout(() => {
            setShowQR(true);
            setCurrentStep(3);
          }, 2000);
          const cartProductFromStorageAfterCheckout = localStorage.getItem(
            "cartProducts"
          )
            ? JSON.parse(localStorage.getItem("cartProducts"))
            : null;
          const cartProductAfterCheckout = JSON.parse(
            JSON.stringify(cartProductFromStorageAfterCheckout)
          );
          cartProductAfterCheckout.forEach((item) => {
            item.checkedOut = true;
          });
          localStorage.setItem(
            "cartProducts",
            JSON.stringify(cartProductAfterCheckout)
          );
          localStorage.removeItem("cartCount");
          window.dispatchEvent(new CustomEvent("cartUpdated", { detail: 0 }));
        }
      } else {
        dispatch(
          checkoutAction(
            userInfo,
            transformedCartProducts,
            shipment,
            updatedTotalAmount,
            note
          )
        );
        window.dispatchEvent(
          new CustomEvent("cartUpdated", {
            detail: transformedCartProducts?.length,
          })
        );
        setSuccessNotice(true);
        setErrorNotice(false);
        setTimeout(() => {
          setShowQR(true);
          setCurrentStep(3);
        }, 2000);
        const cartProductFromStorageAfterCheckout = localStorage.getItem(
          "cartProducts"
        )
          ? JSON.parse(localStorage.getItem("cartProducts"))
          : null;
        const cartProductAfterCheckout = JSON.parse(
          JSON.stringify(cartProductFromStorageAfterCheckout)
        );
        cartProductAfterCheckout.forEach((item) => {
          item.checkedOut = true;
        });
        localStorage.setItem(
          "cartProducts",
          JSON.stringify(cartProductAfterCheckout)
        );
        localStorage.removeItem("cartCount");
        window.dispatchEvent(new CustomEvent("cartUpdated", { detail: 0 }));
      }
    } else {
      setSuccessNotice(false);
      setErrorNotice(true);
      setTimeout(() => {
        window.location.href = "/menu";
      }, 2000);
    }

    const tempTotalProductPrice = computeTotalPrice(cartProducts);
    const tempTotalOrderFee = getTotalOrderFee(totalProductPrice, feeShipping);
    localStorage.setItem("tempTotalOrderFee", tempTotalOrderFee);

    localStorage.setItem(
      "tempCartProducts",
      localStorage.getItem("cartProducts")
    );
    localStorage.setItem("tempCartCount", localStorage.getItem("cartCount"));
    localStorage.setItem(
      "tempLastCartIdUI",
      localStorage.getItem("lastCartIdUI")
    );
    localStorage.setItem(
      "tempShippingFee",
      localStorage.getItem("shippingFee")
    );
    localStorage.setItem(
      "tempExistProducts",
      localStorage.getItem("existProducts")
    );
    //localStorage.setItem("tempTotalProductPrice", tempTotalProductPrice);
    localStorage.setItem(
      "tempTotalProductPrice",
      JSON.stringify(tempTotalProductPrice)
    );

    localStorage.removeItem("cartProducts");
    localStorage.removeItem("cartCount");
    localStorage.removeItem("lastCartIdUI");
    localStorage.removeItem("shippingFee");
    localStorage.removeItem("existProducts");

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleCartWhenProductsDeleted = async (
    cartItems,
    deletedProducts,
    deletedAttributeProducts
  ) => {
    let updatedCartItems = cartItems;
    if (deletedProducts?.length > 0) {
      updatedCartItems = cartItems.filter(
        (cartItem) =>
          !deletedProducts.some(
            (deletedProduct) => deletedProduct.id === cartItem.id
          )
      );
    }
    if (deletedAttributeProducts?.length > 0) {
      updatedCartItems = cartItems?.filter(
        (cartItem) =>
          !deletedAttributeProducts?.some(
            (deletedAttributeProduct) =>
              deletedAttributeProduct?.id === cartItem?.id &&
              deletedAttributeProduct?.attribute_id === cartItem?.attribute_id
          )
      );
    }
    localStorage.setItem("cartProducts", JSON.stringify(updatedCartItems));
    window.dispatchEvent(
      new CustomEvent("cartUpdated", { detail: updatedCartItems?.length })
    );
    localStorage.setItem("cartCount", updatedCartItems?.length);
    dispatch(addToCart(updatedCartItems));
  };
  async function updateCartItemsWhenProductsUpdated(cartItems, existProducts) {
    cartItems?.forEach((cartItem) => {
      const matchingProduct = existProducts?.find(
        (product) =>
          product?.id === cartItem?.id &&
          product?.attribute_id === cartItem?.attribute_id
      );
      if (matchingProduct) {
        cartItem.attribute_id = matchingProduct?.attribute_id;
        cartItem.category = matchingProduct?.category;
        cartItem.id = matchingProduct?.id;
        cartItem.image = matchingProduct?.image;
        cartItem.name = matchingProduct?.name;
        cartItem.price = matchingProduct?.price;
        cartItem.size = matchingProduct?.size;
      }
    });
    localStorage.setItem("cartProducts", JSON.stringify(cartItems));
    dispatch(addToCart(cartItems));
  }

  const handleConfirm = async () => {
    const fetchProductExistence = async () => {
      const response = await checkExistenceProductList(cartProducts);
      localStorage.setItem(
        "existProducts",
        JSON.stringify(response?.data?.existProducts)
      );
      if (response?.data?.deleted === true) {
        const cartItems = cartProducts;
        const deletedProducts = response?.data?.data?.deletedProducts;
        const deletedAttributeProducts =
          response?.data?.data?.deletedAttributeProducts;
        handleCartWhenProductsDeleted(
          cartItems,
          deletedProducts,
          deletedAttributeProducts
        );
      }
    };
    await fetchProductExistence();
    const cartProductFromStorage = localStorage.getItem("cartProducts")
      ? JSON.parse(localStorage.getItem("cartProducts"))
      : null;
    const existProducts = localStorage.getItem("existProducts")
      ? JSON.parse(localStorage.getItem("existProducts"))
      : null;
    if (cartProductFromStorage?.length > 0) {
      await updateCartItemsWhenProductsUpdated(
        cartProductFromStorage,
        existProducts
      );
      showModal();
    } else {
      setSuccessNotice(false);
      setErrorNotice(true);
      setTimeout(() => {
        window.location.href = "/menu";
      }, 2000);
      localStorage.removeItem("cartProducts");
      localStorage.removeItem("cartCount");
      localStorage.removeItem("lastCartIdUI");
      localStorage.removeItem("shippingFee");
      localStorage.removeItem("existProducts");
    }
  };
  useEffect(() => {
    const tempTotalProductPrice = localStorage.getItem("tempTotalProductPrice")
      ? JSON.parse(localStorage.getItem("tempTotalProductPrice"))
      : 0;

    const tempTotalOrderFee = localStorage.getItem("tempTotalOrderFee")
      ? JSON.parse(localStorage.getItem("tempTotalOrderFee"))
      : 0;

    if (!cartProducts || cartProducts.length === 0) {
      setTotalProductPrice(tempTotalProductPrice);
      setTotalAmount(tempTotalOrderFee);
    } else {
      const computedTotalProductPrice = computeTotalPrice(cartProducts);
      setTotalProductPrice(computedTotalProductPrice);
      setTotalAmount(getTotalOrderFee(computedTotalProductPrice, feeShipping));
    }
  }, [cartProducts, feeShipping]);

  const Total = parseInt(localStorage.getItem("tempTotalProductPrice"));

  const tempTotalOrderFee = parseInt(localStorage.getItem("tempTotalOrderFee"));

  useEffect(() => {
    const address = newAddress === "" ? userInfo?.address : newAddress;
    dispatch(fetchShippingFee(address));

    if (
      cartProducts[0]?.shipment !== null &&
      cartProducts[0]?.shipment !== undefined
    ) {
      setNewAddress(cartProducts[0].shipment);
    }

    const fetchProductExistence = async () => {
      const response = await checkExistenceProductList(cartProducts);
      if (response?.data?.deleted === true) {
        const cartItems = cartProducts;
        const deletedProducts = response?.data?.data?.deletedProducts;
        const deletedAttributeProducts =
          response?.data?.data?.deletedAttributeProducts;
        await handleCartWhenProductsDeleted(
          cartItems,
          deletedProducts,
          deletedAttributeProducts
        );
      }
      const cartProductFromStorage = localStorage.getItem("cartProducts")
        ? JSON.parse(localStorage.getItem("cartProducts"))
        : [];
      updateCartItemsWhenProductsUpdated(
        cartProductFromStorage,
        response?.data?.existProducts
      );
    };
    fetchProductExistence();
    if (SuccessNotice || ErrorNotice) {
      const timeout = setTimeout(() => {
        setSuccessNotice(false);
        setErrorNotice(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [SuccessNotice, ErrorNotice, dispatch, newAddress, shippingFee]);

  const renderModal = ({ status, setStatus }) => {
    return (
      <>
        {isModalOpen ? (
          <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50">
              <div className="relative p-4 w-full max-w-md max-h-full ">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      {t("UpdateAddress")}
                    </h3>
                    <button
                      type="button"
                      className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => {
                        setStatus(false);
                      }}
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only"> {t("Close modal")}</span>
                    </button>
                  </div>

                  <div className="p-4 md:p-5">
                    <form className="space-y-4" action="#">
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          {t("Fullname")}
                        </label>
                        <input
                          disabled
                          value={userInfo?.name}
                          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white cursor-not-allowed"
                          placeholder="Nguyen Van A"
                          required
                        />
                      </div>
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          {t("Phone Number")}
                        </label>
                        <input
                          disabled
                          value={userInfo?.phone}
                          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white cursor-not-allowed"
                          placeholder="0983582241"
                          required
                        />
                      </div>

                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          {t("NewAddress")}
                        </label>
                        <input
                          type="text"
                          ref={newAddressRef}
                          className={`border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 bg-white outline-none ${
                            newAddressError && "border-red-500"
                          }`}
                          placeholder="43c Điện Biên Phủ, Phường 22, Bình Thạnh, Thành phố Hồ Chí Minh"
                          required
                        />
                        {newAddressError && (
                          <p className="text-red-500 text-sm mt-1">
                            {newAddressError}
                          </p>
                        )}
                      </div>

                      <div className="flex justify-end w-full">
                        <button
                          type="button"
                          className="bg-gray-400 hover:bg-gray-500 mr-4 text-white font-bold py-2 px-2 md:px-4 rounded min-w-20 ml-4"
                          onClick={() => setIsModalOpen(false)}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          onClick={handleUpdate}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 md:px-4 rounded min-w-20"
                        >
                          {t("Update")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const newAddressValue = newAddressRef.current.value;
    if (newAddressValue.length < 10 || newAddressValue.length > 64) {
      setNewAddressError(t("NewAddressValidate"));
      return;
    }
    setNewAddress(newAddressValue);
    setNewAddressError("");
    toggleModal();
    selectPickAtStoreMethod();
  };
  return (
    <div className="h-full md:h-dvh w-full bg-[#F2EBE6] mt-12 md:mt-24 pb-2 px-4 md:px-44  ">
      {renderModal({ status: isModalOpen, setStatus: setIsModalOpen })}
      <div className=" w-full h-1/6 flex justify-center items-center">
        <ProcessBar currentStep={currentStep} steps={steps} />
      </div>
      <Modal
        title={t("Please check carefully before placing an order")}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          value={note}
          onChange={handleNoteChange}
          placeholder={t("Your note...")}
        />
      </Modal>
      <div className="absolute z-50">
        {SuccessNotice && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-10 right-2 md:right-10"
          >
            <Alert
              message={
                <span>
                  <ExclamationCircleOutlined style={{ color: "Green" }} />{" "}
                  {t("Success")}{" "}
                </span>
              }
              description={t("Checkout successfully")}
              type="success"
              closable
              onClose={() => setSuccessNotice(false)}
            />
          </motion.div>
        )}
        {ErrorNotice && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-10 right-2 md:right-10"
          >
            <Alert
              message={
                <span>
                  <ExclamationCircleOutlined style={{ color: "red" }} />{" "}
                  {t("Error")}{" "}
                </span>
              }
              description={t("Check out fail (All products were deleted)")}
              type="error"
              closable
              onClose={() => setErrorNotice(false)}
            />
          </motion.div>
        )}
      </div>

      <div className=" w-full  flex justify-center items-center sm:px-0 mt-2 md:mt-1">
        <div className="w-full  h-full  rounded-xl bg-white p-2">
          {/* Header thông tin nhận hàng */}

          <div className="w-full  flex items-center border-b-2 ">
            {!(cartProducts.length == 0) && (
              <Link to={"/cart"} className="arrow-link text-black">
                <ArrowLeftOutlined className="text-black font-bold text-2xl cursor-pointer mr-1 hover:text-gray-500" />
              </Link>
            )}
            {/* <Link to={"/cart"} className="arrow-link text-black">
              <ArrowLeftOutlined className="text-black font-bold text-2xl cursor-pointer mr-1 hover:text-gray-500" />
            </Link> */}
            <h2 className="font-bold text-xl py-1 mx-2 ">
              {/* {t("Shipping information")} */}
              {t("Delivery address")}
            </h2>
            <ShopOutlined className="font-bold text-2xl" />
          </div>

          {/* Body thông tin nhân hàng */}
          <div className="w-full  flex  justify-start md:justify-between  items-center mt-1 p-2 gap-4 ">
            <div className="flex lg:flex-row flex-col">
              <div className="flex">
                <p className="font-bold text-base">{userInfo?.name}</p>
                <p className="font-bold text-base  md:mx-2">
                  (+84) {userInfo?.phone}
                </p>
              </div>

              <div className="flex md:flex-row flex-col ">
                <p className="text-base  text-wrap w-fit lg:ml-4 ">
                  {newAddress !== "" &&
                  newAddress !== undefined &&
                  newAddress !== null
                    ? newAddress
                    : userInfo?.address}
                </p>
                <div className="border-solid border-2 w-fit  border-red-500 text-red-500 px-1 md:ml-2 ">
                  {t("Default")}
                </div>
              </div>
            </div>
            {!showQR && (
              <div className="mr-2 ">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 md:px-4 rounded min-w-20"
                >
                  {t("Change")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full h-4/6 flex flex-col md:flex-row gap-2 my-2 md:my-0 ">
        {/* Shipping Methods */}
        <div className=" w-full md:w-1/2 lg:w-2/3 h-full flex justify-center items-center ">
          <div className="bg-white w-full  h-[95%] rounded-xl p-4">
            <div className="w-full  flex items-center border-b-2 ">
              {!qrCode ? (
                <h1 className="font-bold text-xl py-1">
                  {t("Shipping methods")}
                </h1>
              ) : (
                <h1 className="font-bold text-xl py-1">{t("Payment")}</h1>
              )}
            </div>
            {/* Div card method payment */}
            {!(cartProducts.length == 0) ? (
              <div className="w-full py-4">
                {/* Nhận hàng ngay tại quầy */}

                <div className="flex justify-center ">
                  <div>
                    <div
                      className={`  flex flex-col items-center rounded-xl shadow-md  cursor-pointer ${
                        currentMethod === 1 && "method-active"
                      } ${currentMethod !== 1 && "method-inactive"}`}
                      onClick={selectPickAtStoreMethod}
                    >
                      <img src={store} className="w-20" alt="Money icon" />
                      <p className="p-4 text-black font-semibold text-md ">
                        {t("Receive your order at the store")}
                      </p>
                      <span
                        className={`hidden justify-center items-center absolute w-8 h-8 bg-[#779CFE] rounded-full top-0 right-0 translate-x-2 -translate-y-2 ${
                          currentMethod === 1 && "icon-active"
                        }`}
                        id="icon"
                      >
                        <CheckOutlined
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  {/* Ahamove ship*/}
                  {/* <div
                    className={`ml-4  flex flex-col items-center rounded-xl shadow-md  cursor-pointer ${
                      currentMethod === 2 && "method-active"
                    } ${currentMethod !== 2 && "method-inactive"}`}
                    onClick={selectaHamoveMethod}
                  >
                    <img
                      src={deliveryIcon}
                      className="w-1/3"
                      alt="Ahamove icon"
                    />
                    <p className="p-4  text-black font-semibold text-md ">
                      {t("Receive orders via Ahamove")}
                    </p>
                    <span
                      className={`hidden justify-center items-center absolute w-8 h-8 bg-[#779CFE] rounded-full top-0 right-0 translate-x-2 -translate-y-2 ${
                        currentMethod === 2 && "icon-active"
                      }`}
                      id="icon"
                    >
                      <CheckOutlined
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      />
                    </span>
                  </div> */}
                  {/* Ahamove ship*/}
                  <div
                    className={`ml-4 flex flex-col items-center rounded-xl shadow-md  cursor-pointer ${
                      currentMethod === 2 && "method-active"
                    } ${currentMethod !== 2 && "method-inactive"}`}
                    onClick={selectaHamoveMethod}
                  >
                    <img
                      src={deliveryIcon}
                      className="w-1/3"
                      alt="Ahamove icon"
                    />
                    <p className="p-4  text-black font-semibold text-md ">
                      {t("Receive orders via Ahamove")}
                    </p>
                    <span
                      className={`hidden justify-center items-center absolute w-8 h-8 bg-[#779CFE] rounded-full top-0 right-0 translate-x-2 -translate-y-2 ${
                        currentMethod === 2 && "icon-active"
                      }`}
                      id="icon"
                    >
                      <CheckOutlined
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "white",
                        }}
                      />
                    </span>
                    <div className="flex flex-col">
                      {currentMethod === 2 && (
                        <div className=" inset-0 flex flex-col items-center justify-center ">
                          <div className="bg-white border border-[#779CFE] p-6 w-full rounded-lg shadow-md flex flex-col items-center absolute mt-28  ">
                            <div className="mb-4">
                              <label className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={cashOnDelivery}
                                  onChange={handleCashOnDeliveryChange}
                                  className="mr-2 text-black font-semibold text-md"
                                />
                                <span>Cash on Delivery</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <img
                  src={store}
                  alt="store icon"
                  className="w-44 h-44 mt-10 justify-center items-center mx-auto pt-12"
                /> */}
                {currentMethod === 1 ? (
                  <img
                    src={store}
                    alt="store icon"
                    className="w-60 h-60 mt-10 justify-center items-center mx-auto pt-12"
                  />
                ) : currentMethod === 2 ? (
                  <img
                    src={deliveryIcon}
                    alt="delivery icon"
                    className="w-60 h-60 mt-10 justify-center items-center mx-auto pt-12"
                  />
                ) : null}
              </div>
            ) : (
              <div className="flex md:flex-row flex-col mt-14 ">
                <div className="w-full flex flex-col justify-center items-center ">
                  {/* Logo */}
                  <img
                    src={vietQR}
                    alt="vietQR"
                    className="mb-2 w-32 h-18 object-contain"
                  ></img>

                  {/* QR code */}
                  <img
                    src={pieCode}
                    alt="mrPie qrcode"
                    className="size-44 object-contain"
                  ></img>

                  <img
                    src={napas}
                    alt="vietQR"
                    className="mt-2 w-32 h-18 object-contain"
                  ></img>
                  {/* Logo */}
                </div>
                <div className="w-full px-8 flex items-center mt-4 md:mt-0">
                  <div className="w-full md:w-2/3 bg-[#F8F8FA] rounded-md p-4 border-solid border-2 border-gray-300">
                    <div className="mb-2">
                      <p className="text-base text-gray-">Account Name</p>
                      <p className="text-lg text-black font-semibold">
                        KIM JIHYEON
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-base text-gray-">Account Number</p>
                      <p className="text-lg text-black font-semibold">
                        700-010-776130
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-base text-gray-">Bank Name</p>
                      <p className="text-lg text-black font-semibold">
                        Shinhan bank Viet Nam
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Payment */}

        {/* Check Order */}
        <div className="w-full md:w-1/2 lg:w-1/3 h-full flex justify-center items-center mt-4 md:mt-0 mr-1 ">
          <div className=" w-full h-[95%]  flex flex-col justify-center items-center ">
            <div className=" w-full h-full flex justify-center items-center mb-4 md:mb-0">
              <div className=" w-full h-full flex flex-col justify-between  rounded-xl bg-white p-4">
                {/* Header Kiem tra don hang */}
                <div className="w-full  flex items-center border-b-2 ">
                  <h1 className="font-bold text-xl py-1">
                    {t("Check your order")}
                  </h1>
                </div>

                {/* Body chua san pham */}
                <div className="overflow-auto w-full flex flex-col p-3 flex-1 ">
                  <ProductChecking orderItems={cartProducts} />
                </div>

                <div className="w-full  border-t-2 mt-2 p-3">
                  <div className="w-full flex flex-col mt-6">
                    <div className="flex justify-between items-center">
                      <p className="text-black font-bold text-sm">
                        {t("Order cost")}
                      </p>
                      <p className="text-black font-bold text-sm">
                        {cartProducts?.length === 0
                          ? formatMoney(TotalProductPrice)
                          : formatMoney(computeTotalPrice(cartProducts))}{" "}
                        VND
                      </p>
                    </div>
                    <div className="flex justify-between items-center mt-1">
                      <p className="text-black font-bold text-sm">
                        {t("Shipping fee")}
                      </p>
                      <p className="text-black font-bold text-sm">
                        {/* {feeShipping} */}
                        {typeof feeShipping === "number"
                          ? `${formatMoney(feeShipping)} VND`
                          : feeShipping}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center ">
                    <p className="font-bold text-2xl text-black">
                      {t("Total")}
                    </p>
                    <p className="font-bold text-xl">
                      {cartProducts?.length === 0
                        ? formatMoney(totalAmount)
                        : formatMoney(
                            getTotalOrderFee(
                              computeTotalPrice(cartProducts),
                              feeShipping
                            )
                          )}{" "}
                      VND
                    </p>
                  </div>
                  {/* {!(cartProducts[0].checkedOut === true) ? (
                    <div className="text-right mt-2">
                      <button
                        onClick={handleConfirm}
                        disabled={cartProducts[0].checkedOut === true}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        {t("Confirm")}
                      </button>
                    </div>
                  ) : (
                    <div className="text-right mt-2">
                      
                      <Link to={"/order_history"}>
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                          {t("Confirm")}
                        </button>
                      </Link>
                    </div>
                  )} */}
                  {!(cartProducts?.length == 0) ? (
                    <div className="text-right mt-2">
                      <button
                        onClick={handleConfirm}
                        disabled={cartProducts?.length == 0}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      >
                        {t("Confirm")}
                      </button>
                    </div>
                  ) : (
                    <div className="text-right mt-2">
                      {userInfo?._id !== undefined && (
                        <Link to={"/order_history"}>
                          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            {t("Move to Order History")}
                          </button>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
