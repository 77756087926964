import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import EventList from "./event-list";
import Store from "./store";

import { RightOutlined, DownOutlined } from "@ant-design/icons";
// import bg_promotion from "../../../assets/images/bg-promotion.jpg";
const Eventmenu = () => {
  const [activeTab, setActiveTab] = useState("event");
  const [isVisible, setIsVisible] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState("all");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleTabClick = (tab) => {
    setIsVisible(false);
    setTimeout(() => {
      setActiveTab(tab);
      setIsVisible(true);

      if (tab === "event") {
        handleSubTabClick("all");
      }
    });
  };
  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  return (
    <div
      className="intro-container min-h-screen font-roboto text-sm bg-[#F2EBE6] lg:pt-10"
      style={{
        // backgroundImage: `url(${bg_promotion})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex  bg-[#F8F8F8] text-black shadow-md mt-12 md:mt-24 p-4 px-4 md:px-44">
        <Link to="/">
          <p className="text-xl text-black font-normal ">{t("Home")}</p>
        </Link>
        <RightOutlined className="text-sm mx-3" />

        <p className="text-xl text-black font-bold ">{t("Event")}</p>
      </div>

      <div className="title-container relative font-AlfaSlabOne mt-16 pb-10 ">
        <h1 className="mt-8 md:ml-10 mb-20  text-4xl text-center md:text-left lg:px-36">
          {t("EVENT")}
        </h1>
        <div className="tab-container grid items-center grid-cols-2 font-AlfaSlabOne justify-center mt-8  pb-2 ">
          <button
            onClick={() => handleTabClick("event")}
            className={`  text-2xl py-4  ${
              activeTab === "event"
                ? "text-[#3a3737]  lg:ml-48 border-2 border-[#3a3737] md:border-t-2 md:border-r-2 md:border-l-2 font-bold hover:text-[#74493e]"
                : "text-black/70 lg:ml-48  bg-gray-300 border-[#3a3737]  hover:text-[#74493e]"
            }`}
          >
            {t("Event")}
          </button>
          <button
            onClick={() => handleTabClick("tab2")}
            className={` text-2xl py-4  ${
              activeTab === "tab2"
                ? "text-[#3a3737] lg:mr-48 border-2 border-[#3a3737] md:border-t-2 md:border-r-2 md:border-l-2  font-bold hover:text-[#74493e]"
                : "text-black/70 lg:mr-48 bg-gray-300 border-[#3a3737] hover:text-[#74493e]"
            }`}
          >
            {t("NewStore")}
          </button>
        </div>

        <div className="tab-content mt-8 md:ml-8 md:mr-8 mx-4 relative ">
          {activeTab === "event" && (
            <>
              <div className="subtab-container md:pr-40 md:absolute top-0 right-0 mr-2 md:-mt-56 text-xl  flex py-3 ">
                <button
                  onClick={() => handleSubTabClick("all")}
                  className={`md:mx-3  mx-auto md:text-2xl ${
                    activeSubTab === "all"
                      ? "text-[#e75050] border-b-2 border-[#e75050] font-bold "
                      : "text-gray-500 hover:text-[#dac9c5] "
                  }`}
                >
                  {t("AllEvent")}
                </button>
                <button
                  onClick={() => handleSubTabClick("ongoing")}
                  className={`md:mx-5 mx-auto md:text-2xl ${
                    activeSubTab === "ongoing"
                      ? "text-[#e75050] border-b-2 border-[#e75050] font-bold "
                      : "text-gray-500 hover:text-[#dac9c5] "
                  }`}
                >
                  {t("OngoingEvent")}
                </button>
                <button
                  onClick={() => handleSubTabClick("ended")}
                  className={`md:mx-3 mx-auto  md:text-2xl ${
                    activeSubTab === "ended"
                      ? "text-[#e75050] border-b-2 border-[#e75050] font-bold "
                      : "text-gray-500 hover:text-[#dac9c5] "
                  }`}
                >
                  {t("EndedEvent")}
                </button>
              </div>

              {activeSubTab === "all" && <EventList />}

              {activeSubTab === "ongoing" && <EventList />}

              {activeSubTab === "ended" && <EventList />}
            </>
          )}

          {activeTab === "tab2" && <Store />}
        </div>

        {showScrollButton && (
          <div className="fixed bottom-0 left-0 right-0  ">
            <div className="flex justify-center mt-8 mb-4 ">
              <button
                onClick={scrollToBottom}
                className="bg-black hover:bg-gray-700 mx-auto text-white font-bold  py-2 px-10  rounded-xl"
              >
                <DownOutlined />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Eventmenu;
