import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Radio, ConfigProvider, Space } from "antd";

import { listCategory } from "../../../actions/categoryAction";
import i18n from "../../../i18n";
const RadioButton = ({ selectedCate, setSelectedCate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const categoryList = useSelector((state) => state.categoryList);
  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  const onChange = (e) => {
    setSelectedCate(e.target.value);
  };
  const [languageChange, setLanguageChange] = useState(i18n.language);
  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#EAAEAE",
          borderRadius: 2,
          colorBgContainer: "#F8F2F2",
        },
        components: {
          Radio: {
            radioSize: 30,
            dotSize: 16,
          },
        },
      }}
    >
      <Radio.Group
        size="small"
        onChange={onChange}
        value={selectedCate}
        className="ml-8 mt-2"
      >
        <Space
          direction="vertical"
          className="text-2xl font-semibold"
          size="middle"
        >
          <Radio
            defaultChecked={true}
            value={0}
            key={0}
            className="text-base items-center"
          >
            {t("All")}
          </Radio>
          {categoryList?.categoryList?.map((el, index) => (
            <Radio
              value={el.id}
              key={el.id}
              className="text-base items-center pr-2"
            >
              {languageChange === "en" ? el?.name_en : el?.name}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </ConfigProvider>
  );
};

export default RadioButton;
