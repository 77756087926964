import axios from "axios";
export const SHIPPING_FEE_REQUEST = "SHIPPING_FEE_REQUEST";
export const SHIPPING_FEE_SUCCESS = "SHIPPING_FEE_SUCCESS";
export const SHIPPING_FEE_FAIL = "SHIPPING_FEE_FAIL";

axios.defaults.baseURL = process.env.BASE_URL;

const getShippingFee = async (userAddress) => {
  const requestBody = {
    path: [
      {
        address: userAddress,
      },
      {
        address:
          "63 Nguyễn Cừ, Thảo Điền, Quận 2, Thành phố Hồ Chí Minh, Vietnam",
      },
    ],
  };
  const { data } = await axios.post(
    "/api/v1/user/order/ahamove_shipping_fee",
    requestBody
  );
  return data;
};

// Action Creator for fetching shipping fee
export const fetchShippingFee = (userAddress) => async (dispatch) => {
  dispatch({ type: SHIPPING_FEE_REQUEST });
  try {
    const objectResult = await getShippingFee(userAddress);
    dispatch({
      type: SHIPPING_FEE_SUCCESS,
      payload: objectResult.data.AhamoveShippingFee,
    });
    localStorage.setItem("shippingFee", objectResult.data.AhamoveShippingFee);
  } catch (error) {
    dispatch({
      type: SHIPPING_FEE_FAIL,
      payload: error.response
        ? error.response.data
        : "Failed to fetch shipping fee",
    });
  }
};
