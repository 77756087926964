import {
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAIL,
  STORE_LIST_RESET,
} from "../constants/storeConstant";

export const storeListReducer = (
  state = { loading: true, productList: [] },
  action
) => {
  switch (action.type) {
    case STORE_LIST_REQUEST:
      return { loading: true, storeList: [] };
    case STORE_LIST_SUCCESS:
      return {
        loading: false,
        storeList: action.payload.data.stores,
        pageSize: action.payload.data.pages,
        page: action.payload.data.page,
      };
    case STORE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case STORE_LIST_RESET:
      return { storeList: [] };
    default:
      return state;
  }
};
