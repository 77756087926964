import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
  PRODUCT_RESET,
} from "../constants/productConstant";

export const productListReducer = (
  state = { loading: true, productList: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, productList: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        productList: action.payload.data.products,
        pageSize: action.payload.data.pages,
        page: action.payload.data.page,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_LIST_RESET:
      return { productList: [] };
    default:
      return state;
  }
};

export const productDetailReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_SUCCESS:
      return { loading: false, product: action.payload.data };
    case PRODUCT_FAIL:
      return { loading: false, error: action.payload.error };
    case PRODUCT_RESET:
      return { product: {} };
    default:
      return state;
  }
};
