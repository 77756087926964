import axios from "axios";
// Action Types
export const CART_LIST_REQUEST = "CART_LIST_REQUEST";
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS";
export const CART_LIST_FAIL = "CART_LIST_FAIL";

axios.defaults.baseURL = process.env.BASE_URL;

// Action Creator for fetching cart products
export const addToCart = (data) => async (dispatch) => {
  try {
    dispatch({ type: CART_LIST_REQUEST });
    dispatch({ type: CART_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CART_LIST_FAIL,
      payload: error.message || "Failed to fetch data",
    });
  }
};
