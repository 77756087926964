import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { Typography, Flex } from "antd";
// import {
//   ShoppingCartOutlined,
//   SearchOutlined,
//   // HeartOutlined,
// } from "@ant-design/icons";
import { defaultImage } from "../../../../assets/images";
import Notification from "../../notification/notification";
import { formatMoney } from "../../../../utils/helpers";

const { Title } = Typography;

const setPriceRange = (attributes) => {
  if (!attributes || attributes?.length === 0) {
    return { price: `0,000 - 0,000` };
  }

  if (attributes?.length === 1) {
    return {
      price: attributes[0].product_price,
    };
  }
  if (attributes?.length === 1 && attributes[0]?.product_price === 0) {
    // return { price: "" };
    return;
  }
  // attributes?.sort((a, b) => a.product_price - b.product_price);
  attributes = attributes
    ?.filter((item) => item.product_price !== 0)
    .sort((a, b) => a.product_price - b.product_price);
  return {
    price: `${formatMoney(attributes[0]?.product_price)} - ${formatMoney(
      attributes[attributes?.length - 1]?.product_price
    )}`,
  };
};

const Item = ({ item, isShow = false }) => {
  const [actived, setActived] = useState(-1);
  const [isHovered, setIsHovered] = useState(true);
  // const [favoriteItem, setFavoriteItem] = useState([]);

  const handleHover = (key) => {
    setIsHovered(false);
    setActived(key);
  };

  const handleUnHover = () => {
    setIsHovered(true);
    setActived(-1);
  };

  // const hdIsFavorite = (item) => {
  //   setFavoriteItem((prev) => [...prev, { id: item.key, title: item.title }]);
  // };

  const [languageChange, setLanguageChange] = useState("");

  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };

    window.addEventListener("languageChanged", handleLanguageChange);

    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className="relative w-full h-full ">
      {/* {favoriteItem.length > 0 && <Notification data={favoriteItem} />} */}
      <div
        className={` text-center p-2 md:p-4 ${
          !isHovered && actived === item.key ? "blur-sm" : "blur-none"
        }`}
        onMouseOver={() => handleHover(item.key)}
        onMouseLeave={handleUnHover}
      >
        <img
          className="mr-auto  ml-auto w-80 h-52  mt-2  object-fit rounded-xl"
          src={item?.image ? item?.image : defaultImage}
          alt={item.name}
        />
        {!isShow && (
          <div className="mt-2">
            <div className="h-14">
              <Title level={4}>
                {languageChange === "en" ? item.name_en : item.name}
              </Title>
            </div>

            <span className="text-base md:text-xl text-black font-bold">
              {setPriceRange(item.attributes)?.price}
            </span>
          </div>
        )}
      </div>
      {!isHovered && actived === item.key && (
        <Flex
          className="flex justify-around w-1/2 absolute top-1/2 transform -translate-y-1/2 translate-x-1/2"
          onMouseOver={() => handleHover(item.key)}
          onMouseLeave={handleUnHover}
        >
          {/* <Link to={`/detail/${item.id}`}>
            <div className="text-2xl text-white border w-10 h-10 rounded-full hover:bg-[#E96161] flex justify-center items-center">
              <SearchOutlined className="p-1 bg-neutral-800 w-7 h-7 " />
            </div>
          </Link> */}
          {/*  Add product into favorite */}
          {/* <Button
          <Button
            onClick={() => hdIsFavorite(item)}
            className="bg-neutral-800 hover:!bg-[#E96161] border-solid border-1 border-white"
            type="primary"
            shape="circle"
            icon={<HeartOutlined />}
          /> */}

          {/* link to shopping cart */}

          {/* <Link to="/cart">
            <div className="text-2xl text-white border w-10 h-10 rounded-full hover:bg-[#E96161] flex justify-center items-center">
              <ShoppingCartOutlined className="p-1 bg-neutral-800 w-7 h-7 " />
            </div>
          </Link> */}
        </Flex>
      )}
    </div>
  );
};

export default Item;
