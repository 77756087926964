import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";

import { Typography, Input } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";

import RadioButton from "./radio-button";
// import TagContent from "./tag-content";

import "../menu/menu.css";

const SideBar = ({
  isOpen,
  SetIsOpen,
  selectedCate,
  setSelectedCate,
  keyword,
  setKeyword,
}) => {
  const { Title } = Typography;

  const { t } = useTranslation();
  const onClose = () => {
    return SetIsOpen(false);
  };
  const [term, setTerm] = useState("");

  const handleSearch = () => {
    setKeyword(term);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setKeyword(term);
    }
  };
  return (
    <div className="w-full h-full ">
      <div className="flex justify-between pt-3 mt-4">
        <Title level={4} className="ml-8 md:mt-20  text-md">
          {t("SEARCH")}
        </Title>
        <div className=" cursor-pointer pr-4 md:hidden" onClick={onClose}>
          <CloseOutlined style={{ fontSize: 24, fontWeight: "bold" }} />
        </div>
      </div>

      <div className="mt-5 mr-8 ml-3">
        <Input
          size="large"
          placeholder={t("Search by name...")}
          className="rounded-3xl py-0  ml-4 h-8  font-semibold text-xl"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          onPressEnter={handleKeyPress}
          suffix={<SearchOutlined onClick={handleSearch} />}
        />
      </div>
      <div className="mt-14">
        <Title className="ml-8 mt-8" level={4}>
          {t("CATEGORIES")}
        </Title>
        <RadioButton
          selectedCate={selectedCate ? selectedCate : 0}
          setSelectedCate={setSelectedCate}
        />
      </div>
      {/* <div className="mt-14 ml-8 gap-3  ">
        <Title level={4}> {t("Tag")}</Title>
        <TagContent data={data} />
      </div> */}
    </div>
  );
};

export default SideBar;
