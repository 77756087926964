import React from "react";
import { useDispatch } from "react-redux";
import { Link,useNavigate } from "react-router-dom";

import "./dropdown.css";

import { Dropdown } from "antd";
import {
  UserOutlined,
  ContainerOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { logout } from "../../../../actions/userAction";

const DropDownUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    localStorage.setItem(
      "cartProducts",
      "[]"
    );
    localStorage.removeItem("cartCount");
    localStorage.removeItem("lastCartIdUI");
    localStorage.removeItem("shippingFee");
    localStorage.removeItem("orderDetail");
    localStorage.removeItem("existProducts");
    window.dispatchEvent(
      new CustomEvent("cartUpdated", {
        detail: 0,
      })
    );
    navigate("/login");
  };

  const items = [
    {
      key: "1",
      label: (
        <Link to="/user_profile" className="font-semibold">
          {t('UserProfile')}
        </Link>
      ),
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: (
        <Link to="/order_history" className="font-semibold">
         {t('OrderHistory')}
        </Link>
      ),
      icon: <ContainerOutlined />,
    },
    {
      key: "3",
      label: <p className="font-semibold">{t('Logout')}</p>,
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight" arrow>
      <UserOutlined className="mr-5 text-2xl text-black relative" />
    </Dropdown>
  );
};

export default DropDownUser;
