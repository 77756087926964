import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import {
  checkOrderHistory,
  UpdateOrderStatus,
} from "../../../services/order/orderService";
import i18n from "../../../i18n";
import { bread, defaultImage, user } from "../../../assets/images/index";
const UserProfile = () => {
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [orderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  const fetchOrderHistory = async () => {
    try {
      const response = await checkOrderHistory(userInfo.data.token);
      const orders = response.data.orders;
      setOrderHistory(orders);
    } catch (error) {
      // console.error("Error fetching order history:", error);
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return t("PENDING");
      case 1:
        return t("PAID");
      case 2:
        return t("COMPLETE");
      case 3:
        return t("CANCELED");
      default:
        return "";
    }
  };

  // const handleCancel = async (orderId) => {
  //   try {
  //     await UpdateOrderStatus(orderId, 3, userInfo.data.token);
  //     fetchOrderHistory();
  //   } catch (error) {
  //     // console.error("Error updating order status:", error);
  //   }
  // };

  const [languageChange, setLanguageChange] = useState(i18n.language);
  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className="w-full h-auto md:h-dvh bg-[#F2EBE6] font-roboto mt-12 md:mt-24 ">
      {/* Header Image */}
      <div className="relative w-full h-44 md:h-1/5 ">
        <div className="w-full h-full bg-gradient-to-r from-[#C7A186] to-[#B08972]"></div>
        <div className="absolute  w-full px-8 md:px-24 top-1/2 -translate-y-1/2 text-white flex justify-between ">
          {/* Left side content */}
          <div>
            <h1 className="text-3xl font-bold ">{`Welcome ${userInfo.data.name}`}</h1>
            <p className="text-base mt-2">
              {t(`Welcome to your Mr.Pie Profile's`)}
            </p>
          </div>
        </div>
      </div>

      {/* Profile Content */}
      <div className="md:absolute md:-translate-y-8  pt-4 md:pt-0 w-full h-auto md:h-3/5  px-8  md:px-24 pb-6 ">
        {/* UserProfile */}
        <div className=" w-full h-2/3 flex-col-reverse md:flex-row  flex  gap-4  ">
          {/* Left Content  UserProfile*/}
          <div className="w-full md:w-3/4 h-full bg-white rounded-xl shadow-lg py-2 md:py-6 px-2 md:px-8">
            {/* Title and image */}
            <div className="flex flex-col">
              <p className="text-lg md:text-2xl px-2 pt-2 md:p-0 font-bold text-[#1C3256]">
                {t(`My Profile`)}
              </p>
            </div>

            {/* Input field */}
            <div className="w-full mt-4 md:mt-6 p-2 flex flex-col gap-4 md:gap-8">
              {/* Name */}
              <div className="flex flex-col gap-2 md:gap-0 md:flex-row justify-around items-center">
                <div className="w-full md:w-[45%] h-14 bg-[#F4F4F4] border-2 focus-within:bg-white  rounded-xl shadow-md px-3 py-1 cursor-not-allowed">
                  <p className="text-[#B2B2B2] font-semibold">{t(`Name`)}</p>
                  <input
                    type="text"
                    disabled
                    value={userInfo.data.name}
                    className="appearance-none border-0  w-full text-black   leading-4 outline-none bg-[#F4F4F4] focus:bg-white font-semibold cursor-not-allowed text-base py-1  "
                  />
                </div>
                <div className="w-full md:w-[45%] mt-2 md:mt-0 h-16 bg-[#F4F4F4] border-2 focus-within:bg-white focus-within:border-[#1890FF] rounded-xl shadow-md px-3 py-2 cursor-not-allowed">
                  <p className="text-[#B2B2B2] font-semibold">
                    {t(`Phone Number`)}
                  </p>
                  <input
                    type="text"
                    disabled
                    value={userInfo.data.phone}
                    className="appearance-none border-0  w-full text-black   leading-4 outline-none bg-[#F4F4F4] focus:bg-white font-semibold cursor-not-allowed text-base py-1  "
                  />
                </div>
              </div>

              {/* Email + Address  */}
              <div className="flex flex-col md:flex-row justify-around items-center gap-2 md:gap-0">
                {/* Email field */}
                <div className="w-full md:w-[45%] h-14 bg-[#F4F4F4] border-2 focus-within:bg-white focus-within:border-[#1890FF] rounded-xl shadow-md px-3 py-1 cursor-not-allowed">
                  <p className="text-[#B2B2B2] font-semibold">{t(`Email`)}</p>
                  <input
                    type="text"
                    disabled
                    value={userInfo.data.email}
                    className="appearance-none border-0  w-full text-black font-semibold  leading-4 outline-none bg-[#F4F4F4] focus:bg-white cursor-not-allowed  text-base py-1"
                  />
                </div>

                {/* Address Field */}
                <div className="w-full md:w-[45%] mt-2 md:mt-0 h-14 bg-[#F4F4F4] border-2 focus-within:bg-white focus-within:border-[#1890FF] rounded-xl shadow-md px-3 py-1 cursor-not-allowed ">
                  <p className="text-[#B2B2B2] font-semibold">{t(`Address`)}</p>
                  <input
                    type="text"
                    disabled
                    value={userInfo.data.address}
                    className="appearance-none border-0  w-full text-black font-semibold leading-4 outline-none bg-[#F4F4F4] focus:bg-white  text-base py-1 cursor-not-allowed truncate"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Content UserProfile */}
          <div className="w-full md:w-1/4 h-full bg-white rounded-xl shadow-lg py-4 px-8">
            <div className="flex flex-col justify-center  items-center text-center">
              <img src={user} alt="userLogo" className="rounded-full size-32" />
              <h2 className="font-bold text-xl mt-4 ">{userInfo.data.name}</h2>
              <p className="mt-2 font-semibold text-[#B08972]">
                {t(`Loyal customer`)}
              </p>
            </div>
          </div>
        </div>
        {/* Order History */}
        <div className="w-full  bg-white rounded-xl shadow-lg py-4 mt-4 px-2 md:px-8  ">
          <div className="flex flex-col">
            <p className="text-lg md:text-xl px-4 font-bold text-[#1C3256]">
              {t(`Recent Order History`)}
            </p>
          </div>

          {/* Product card */}

          <div className="pt-4  justify-center items-center ">
            <div className="overflow-y-auto max-h-[200px]  ">
              {orderHistory[0]?.orderItems?.map((product) => {
                return (
                  <div key={product.id} className="mx-auto  py-4">
                    <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 ">
                      <div className=" items-center lg:mx-auto md:justify-around ">
                        <img
                          src={product?.product?.image ? product?.product?.image : defaultImage}
                          alt="productImage"
                          className="w-1/2  mb-4 rounded-xl shadow-md"
                        />
                      </div>
                      <div className="lg:col-span-3 pr-20 lg:mx-20 flex text-xl items-center ">
                        <div className="md:text-center ">
                          <h3 className="font-semibold  mr-10 mx-18 text-[#9fa8c1]">
                            {t("Product")}
                            <p className="font-semibold line-clamp-1  text-left  mr-18 text-[#1C3256]">
                              {languageChange === "en"
                                ? product?.product?.name_en
                                : product?.product?.name}
                            </p>
                          </h3>
                        </div>
                        <div>
                          <h3 className="font-semibold text-xl  text-center text-[#9fa8c1]">
                            {t("Quantitises")}
                          </h3>
                          <p className="font-semibold text-xl mx-20 text-center text-[#1C3256]">
                            {product?.quantity}
                          </p>
                        </div>
                        <div>
                          <h3 className="font-semibold text-xl text-center mx-18 text-[#9fa8c1]">
                            {t("Size")}
                          </h3>
                          <p className="font-semibold text-xl text-center mx-20 text-[#1C3256]">
                            {product?.attribute?.product_size}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Link to="/order_history">
            <div className=" flex justify-center">
              <button className="w-24 md:w-32 mt-4 h-12 bg-red-400 rounded-xl text-white font-semibold text-center shadow-lg hover:bg-red-300 ">
                {t("View History")}
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
