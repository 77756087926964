import axios from "axios";
axios.defaults.baseURL = process.env.BASE_URL;
export const checkExistenceProductList = async (productList) => {
  const requestBody = {
    productList: productList,
  };
  const response = await axios.post(
    "/api/v1/user/product/existence",
    requestBody
  );
  return response;
};
