import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Layout, Badge, Button, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  ContainerOutlined,
  GiftOutlined,
  CustomerServiceOutlined,
  ShopOutlined,
  UserOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import DropDownUser from "./components/drop-down-user";
import DropDownLanguage from "./components/drop-down-language";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { logo } from "../../../assets/images/index";
import "../header/header.css";
import { logout } from "../../../actions/userAction";

const { Header: HeaderAnt, Sider } = Layout;

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define changeLanguage here
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.setItem("cartProducts", "[]");
    localStorage.removeItem("cartCount");
    localStorage.removeItem("lastCartIdUI");
    localStorage.removeItem("shippingFee");
    localStorage.removeItem("orderDetail");
    localStorage.removeItem("existProducts");
    window.dispatchEvent(new CustomEvent("cartUpdated", { detail: 0 }));
    navigate("/login");
  };

  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const isLoggedIn = userInfo?.success ? userInfo?.success : false;

  const getKeyFromPathname = (pathname) => {
    switch (pathname) {
      case "/":
        return "home";
      case "/about":
        return "about";
      case "/menu":
        return "menu";
      case "/event":
        return "event";
      case "/contact":
        return "contact";
      default:
        return "home";
    }
  };
  const selectedKey = getKeyFromPathname(location.pathname);

  const { t } = useTranslation();

  const [cartCount, setCartCount] = useState(
    localStorage.getItem("cartCount") || 0
  );
  const [collapsed, setCollapsed] = useState(false);
  const navbarRef = useRef(null);
  const sidebarRef = useRef(null);

  const handleNavbarItemClick = () => {
    // Close sidebar if it's open
    if (sidebar) {
      setSidebar(false);
    }
  };

  const handleOutsideClick = (event) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target) &&
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target)
    ) {
      setSidebar(false);
    }
  };

  document.addEventListener("mousedown", handleOutsideClick);

  const handleDestroy = () => {
    document.removeEventListener("mousedown", handleOutsideClick);
  };

  const handleCartUpdate = (event) => {
    setCartCount(event.detail);
  };

  window.addEventListener("cartUpdated", handleCartUpdate);

  return (
    <Layout>
      <div className="header-container w-full ">
        <header className="bg-white md:pb-2" ref={navbarRef}>
          <nav
            className="hidden md:flex w-full items-center justify-between p-4 px-0 md:px-44"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to={"/"}>
                <img className="md:h-24  size-16 w-24 " src={logo} alt="logo" />
              </Link>
            </div>
            <nav className="hidden lg:flex lg:flex-2 gap-x-16 lg:mr-4">
              <Link to={"/"}>
                <p className="text-2xl font-bold leading-6 text-gray-900">
                  {t("Home")}
                </p>
              </Link>
              <Link to={"/menu"}>
                <p className="text-2xl font-bold leading-6 text-gray-900">
                  {t("Menu")}
                </p>
              </Link>
              <Link to={"/event"}>
                <p className="text-2xl font-bold leading-6 text-gray-900">
                  {t("Event")}
                </p>
              </Link>
              <Link to={"/contact"}>
                <p className="text-2xl font-bold leading-6 text-gray-900">
                  {t("Contact")}
                </p>
              </Link>
              <Link to={"/about"}>
                <p className="text-2xl font-bold leading-6 text-gray-900">
                  {t("AboutMe")}
                </p>
              </Link>
            </nav>
            <div className="flex flex-1 justify-end items-center lg:gap-x-6">
              <div className="pt-2">
                <DropDownLanguage changeLanguage={changeLanguage} />
              </div>
              <Link
                to={"/cart"}
                className="flex items-center relative pl-2"
                onClick={handleNavbarItemClick}
              >
                <Badge count={cartCount} showZero>
                  <ShoppingCartOutlined className="text-3xl pr-2 text-black" />
                </Badge>
              </Link>
              {!isLoggedIn ? (
                <Link
                  to={"/login"}
                  className="flex items-center"
                  onClick={handleNavbarItemClick}
                >
                  <LoginOutlined className="text-black text-2xl pr-2" />
                </Link>
              ) : (
                <div className="flex" onClick={handleNavbarItemClick}>
                  <DropDownUser />
                </div>
              )}
            </div>
          </nav>
          <div className="md:hidden relative flex justify-between items-center">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setSidebar(!sidebar)}
              style={{
                fontSize: "16px",
                width: 48,
                height: 48,
                position: "relative",
              }}
            />
            <Sider
              trigger={null}
              className={sidebar ? "nav-menu active" : "nav-menu"}
              style={{ position: "absolute", top: 48 }}
              ref={sidebarRef}
            >
              <div className="demo-logo-vertical" />
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                onClick={() => setSidebar(false)}
                items={[
                  {
                    key: "1",
                    icon: <HomeOutlined />,
                    label: (
                      <Link to="/" onClick={handleNavbarItemClick}>
                        {t("Home")}
                      </Link>
                    ),
                  },
                  {
                    key: "2",
                    icon: <ContainerOutlined />,
                    label: (
                      <Link to="/menu" onClick={handleNavbarItemClick}>
                        {t("Menu")}
                      </Link>
                    ),
                  },
                  {
                    key: "3",
                    icon: <GiftOutlined />,
                    label: (
                      <Link to="/event" onClick={handleNavbarItemClick}>
                        {t("Event")}
                      </Link>
                    ),
                  },
                  {
                    key: "4",
                    icon: <CustomerServiceOutlined />,
                    label: (
                      <Link to="/contact" onClick={handleNavbarItemClick}>
                        {t("Contact")}
                      </Link>
                    ),
                  },
                  {
                    key: "5",
                    icon: <ShopOutlined />,
                    label: (
                      <Link to="/about" onClick={handleNavbarItemClick}>
                        {t("AboutMe")}
                      </Link>
                    ),
                  },
                  // {
                  //   key: "sub1",
                  //   icon: <UserOutlined />,
                  //   label: t("User"),
                  //   children: isLoggedIn
                  //     ? [
                  //         {
                  //           key: "8",
                  //           label: (
                  //             <Link
                  //               to="/user_profile"
                  //               className="font-semibold"
                  //               onClick={handleNavbarItemClick}
                  //             >
                  //               {t("UserProfile")}
                  //             </Link>
                  //           ),
                  //         },
                  //         {
                  //           key: "9",
                  //           label: (
                  //             <Link
                  //               to="/order_history"
                  //               className="font-semibold"
                  //               onClick={handleNavbarItemClick}
                  //             >
                  //               {t("OrderHistory")}
                  //             </Link>
                  //           ),
                  //         },
                  //         {
                  //           key: "10",
                  //           icon: <LoginOutlined />,
                  //           label: t("Logout"),
                  //           onClick: handleLogout,
                  //         },
                  //       ]
                  //     : [
                  //         {
                  //           key: "7",
                  //           icon: <LoginOutlined />,
                  //           label: (
                  //             <Link to="/login" onClick={handleNavbarItemClick}>
                  //               {t("Login")}
                  //             </Link>
                  //           ),
                  //         },
                  //       ],
                  // },
                ]}
              />
            </Sider>
            <div className="mr-2 pt-1 flex">
              <DropDownLanguage changeLanguage={changeLanguage} />
              <Link
                to={"/cart"}
                className="flex items-center relative pl-2 pr-2"
                onClick={handleNavbarItemClick}
              >
                <Badge count={cartCount} showZero>
                  <ShoppingCartOutlined className="text-3xl pr-2 mb-1 text-black" />
                </Badge>
              </Link>
              {!isLoggedIn ? (
                <Link
                  to={"/login"}
                  className="flex  items-center"
                  onClick={handleNavbarItemClick}
                >
                  <LoginOutlined className="text-black text-2xl" />
                </Link>
              ) : (
                <div className="flex" onClick={handleNavbarItemClick}>
                  <DropDownUser />
                </div>
              )}
              
            </div>
          </div>
        </header>
      </div>
    </Layout>
  );
};

export default Header;
