import axios from "axios";
import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_RESET,
} from "../constants/categoryConstant";

axios.defaults.baseURL = process.env.BASE_URL;

export const listCategory =
  (
    page = "",
    pageSize = "",
    keyword = "",
    status = "",
    sort = "",
    order = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORY_LIST_REQUEST,
      });

      const params = {
        page: page,
        pageSize: pageSize,
        keyword: keyword,
        status: status,
        sort: sort,
        order: order,
      };
      const { data } = await axios.get("/api/v1/user/category/list", {
        params: params,
      });

      dispatch({
        type: CATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
