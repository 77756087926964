import {
  CART_LIST_REQUEST,
  CART_LIST_SUCCESS,
  CART_LIST_FAIL,
} from "../actions/cartAction";
const cartProductFromStorage = localStorage.getItem("cartProducts")
  ? JSON.parse(localStorage.getItem("cartProducts"))
  : null;
const initialState = {
  loading: false,
  products: cartProductFromStorage,
  error: null,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_LIST_REQUEST:
      return { ...state, loading: true };
    case CART_LIST_SUCCESS:
      return { loading: false, products: action.payload, error: null };
    case CART_LIST_FAIL:
      return { loading: false, error: action.payload, products: [] };
    default:
      return state;
  }
};
