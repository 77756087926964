// import React, { useState, useEffect } from "react";
// // import { pizza, bread } from "../../../../assets/images/index";
// import { useTranslation } from "react-i18next";
// import { defaultImage } from "../../../../assets/images";
// import i18n from "../../../../i18n";
// import { formatMoney } from "../../../../utils/helpers";
// const ProductChecking = ({ orderItems }) => {
//   const [languageChange, setLanguageChange] = useState(i18n.language);
//   useEffect(() => {
//     const handleLanguageChange = (event) => {
//       setLanguageChange(event.detail);
//     };
//     window.addEventListener("languageChanged", handleLanguageChange);
//     return () => {
//       window.removeEventListener("languageChanged", handleLanguageChange);
//     };
//   }, []);
//   const { t } = useTranslation();
//   return (
//     <div>
//       {orderItems?.map((orderItem) => (
//         <div key={orderItem?.id} className="flex items-center mt-2">
//           <img
//             src={orderItem?.image ? orderItem?.image : defaultImage}
//             className="w-20  rounded-xl min-h-20"
//             alt={languageChange === "en" ? orderItem?.name_en : orderItem?.name}
//           />
//           <div className="flex flex-col justify-between ml-12 ">
//             <p className="font-semibold text-md md:text-lg">
//               {languageChange === "en" ? orderItem?.name_en : orderItem?.name} (
//               {orderItem?.type === 1 && t("Cold")}
//               {orderItem?.type === 2 && t("Hot")})
//             </p>
//             <p className="text-sm md:text-md">
//               {t("Order Quantity")}: {orderItem?.quantity}
//             </p>
//             <p className="text-sm md:text-md">
//               {"Size"}: {orderItem?.size}
//             </p>
//             <p className="font-semibold text-md md:text-lg">
//               {formatMoney(orderItem?.price)} VND
//             </p>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ProductChecking;
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { defaultImage } from "../../../../assets/images";
import i18n from "../../../../i18n";
import { formatMoney } from "../../../../utils/helpers";

const ProductChecking = ({ orderItems }) => {
  const [languageChange, setLanguageChange] = useState(i18n.language);
  const [temporaryItems, setTemporaryItems] = useState([]);
  const [totalProductPrice, setTotalProductPrice] = useState(0);

   const computeTotalPrice = (items) => {
    let totalPrice = 0;
    items?.forEach((item) => {
      totalPrice += item.price * item.quantity;
    });
    return totalPrice;
  };

  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);

    
    if (!orderItems || orderItems.length === 0) {
      const tempItems = localStorage.getItem("tempCartProducts")
        ? JSON.parse(localStorage.getItem("tempCartProducts"))
        : [];
      setTemporaryItems(tempItems);

      const tempTotalProductPrice = localStorage.getItem("tempTotalProductPrice")
        ? JSON.parse(localStorage.getItem("tempTotalProductPrice"))
        : 0;
      setTotalProductPrice(tempTotalProductPrice);
    } else {
      setTotalProductPrice(computeTotalPrice(orderItems)); 
    }

    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, [orderItems]);

  const { t } = useTranslation();
  const itemsToDisplay = orderItems?.length > 0 ? orderItems : temporaryItems;

  return (
    <div>
      {itemsToDisplay?.map((orderItem) => (
        <div key={orderItem?.id} className="flex items-center mt-2">
          <img
            src={orderItem?.image ? orderItem?.image : defaultImage}
            className="w-20 rounded-xl min-h-20"
            alt={languageChange === "en" ? orderItem?.name_en : orderItem?.name}
          />
          <div className="flex flex-col justify-between ml-12">
            <p className="font-semibold text-md md:text-lg">
              {languageChange === "en" ? orderItem?.name_en : orderItem?.name} (
              {orderItem?.type === 1 && t("Cold")}
              {orderItem?.type === 2 && t("Hot")})
            </p>
            <p className="text-sm md:text-md">
              {t("Order Quantity")}: {orderItem?.quantity}
            </p>
            <p className="text-sm md:text-md">
              {"Size"}: {orderItem?.size}
            </p>
            <p className="font-semibold text-md md:text-lg">
              {formatMoney(orderItem?.price)} VND
            </p>
          </div>
        </div>
      ))}
      
    </div>
  );
};

export default ProductChecking;

