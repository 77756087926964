import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { reviewUser } from "../../../actions/reviewAction";
import { checkPermission } from "../../../services/review/reviewService";
import Comment from "./comment";

import { Modal, Rate } from "antd";

const Review = ({ data, productID, rerender, setRerender, ratingScore }) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [permission, setPermission] = useState(false);
  const isLoggedIn = userInfo?.success ? userInfo?.success : false;
  const [rated, setRated] = useState(false);
  const [ratedScore, setRatedScore] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    if (!permission) {
      setComment("");
      return;
    }
    window.location.href = "/login";
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const countReviews = (data) => {
    let count = 0;
    const countReview = data?.reviews?.map((review) => {
      if (review?.rating > 0) count += 1;
    });
    return count;
  };
  const checkRated = (data) => {
    const check = data?.reviews?.find(
      (review) => review.user_id === userInfo?.data?._id && review?.rating > 0
    );

    if (check) {
      setRated(true);
      setRatedScore(check?.rating);
    } else {
      setRated(false);
      setRatedScore(0);
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
      checkRated(data);
    }
  }, [productID, data, isLoggedIn]);
  const fetchData = async () => {
    const response = await checkPermission(productID, userInfo?.data?.token);
    if (response) {
      setPermission(response?.data?.enable);
    }
  };

  const onSubmit = async () => {
    if (!isLoggedIn) {
      setIsModalOpen(true);
      setPermission(true);
    } else {
      if (rating === 0 && comment === "") {
        return;
      }

      if (permission) {
        dispatch(reviewUser(productID, rating, comment));
        setComment("");
        setRating(0);
      } else if (permission === false) {
        setIsModalOpen(true);
      }
      setRerender(!rerender);
    }
  };
  return (
    <div className="flex flex-col">
      {/**rating */}
      <div className=" flex-col md:flex-row flex justify-between text-lg items-center ">
        {/**Left */}
        <div className="md:border-r md:border-gray-300 w-full pb-4  pr-4 md:w-1/2 flex justify-center flex-col items-center">
          <h1 className=" text-2xl font-bold pb-2">
            {t(`Review Our Product`)}
          </h1>
          <span className="text-3xl font-bold">{`${ratingScore}/5`}</span>
          {rated && (
            <span className="text-red-300"> {t(`(You already rated)`)}</span>
          )}
          <span className="pt-2 pb-2">
            {rated ? (
              <Rate disabled value={ratedScore} />
            ) : (
              <Rate onChange={setRating} value={rating} />
            )}
          </span>
          <span>
            {countReviews(data)} {t("reviews")}
          </span>
        </div>
        {/**right */}
        <div className="md:w-1/2 pt-8 md:border-none  border-t border-gray-300 w-full flex justify-center flex-col items-center pb-2 pl-2 pr-2">
          <span className=" text-2xl font-bold pb-6">
            {t(`Leave your Comment`)}
          </span>
          <div className="w-full border-black border rounded-md p-2">
            <textarea
              className="w-full p-2 focus:outline-none"
              placeholder={t(`Place your comment here ...`)}
              value={comment}
              onChange={handleCommentChange}
            />
          </div>
        </div>
      </div>
      {/**button */}
      <div className="flex  justify-center items-center pt-4 pb-2">
        <button
          onClick={onSubmit}
          className="mt-3 text-lg font-semibold border p-2 bg-gradient-to-r from-[#db6c56] to-[#bb457a] rounded-lg text-white  hover:"
          type="submit"
        >
          {t("Submit")}
        </button>
      </div>
      <div className="p-2">
        <Comment data={data} ratedScore={ratedScore} />
      </div>

      <Modal
        title={
          !permission
            ? t("You must buy to leave a review")
            : t("You must login to leave a review")
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {!permission ? (
          <p>{t("Please buy first")}</p>
        ) : (
          <p>{t("Please login first")}</p>
        )}
      </Modal>
    </div>
  );
};

export default Review;
