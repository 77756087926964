import React from "react";
import { Skeleton } from "antd";
const SkeletonLoading = () => {
  return (
    <div className="">
      <div className="block md:hidden">
        <div className="flex- flex-col mt-2 md:block hidden">
          <Skeleton.Image
            active={true}
            size="large"
            style={{ width: 250, height: 250 }}
          />
          <div className="flex flex-col mt-4 gap-2">
            <Skeleton.Input active={true} size="large" style={{ width: 200 }} />
            <Skeleton.Input active={true} size="large" style={{ width: 200 }} />
          </div>
        </div>
        <div className="flex- flex-col ml-8 mt-2 md:hidden block justify-center items-center">
          <Skeleton.Image
            active={true}
            size="large"
            style={{ width: 150, height: 150 }}
          />
          <div className="flex flex-col mt-4 gap-2">
            <Skeleton.Button
              active={true}
              size="large"
              style={{ width: 100 }}
            />
            <Skeleton.Button
              active={true}
              size="large"
              style={{ width: 100 }}
            />
            <Skeleton.Button
              active={true}
              size="large"
              style={{ width: 100 }}
            />
          </div>
          <div className="mt-4">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 150, height: 150 }}
            />
          </div>

          <div className="flex flex-col mt-4 gap-2">
            <Skeleton.Button
              active={true}
              size="large"
              style={{ width: 100 }}
            />
            <Skeleton.Button
              active={true}
              size="large"
              style={{ width: 100 }}
            />
            <Skeleton.Button
              active={true}
              size="large"
              style={{ width: 100 }}
            />
          </div>
        </div>
        <div className="flex- flex-col mt-2">
          <Skeleton.Image
            active={true}
            size="large"
            style={{ width: 250, height: 250 }}
          />
          <div className="flex flex-col mt-4 gap-2">
            <Skeleton.Input active={true} size="large" style={{ width: 200 }} />
            <Skeleton.Input active={true} size="large" style={{ width: 200 }} />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-4">
          <Skeleton.Button active={true} size="large" style={{ width: 50 }} />
          <Skeleton.Button active={true} size="large" style={{ width: 60 }} />
          <Skeleton.Button active={true} size="large" style={{ width: 50 }} />
          <Skeleton.Button active={true} size="large" style={{ width: 70 }} />
          <Skeleton.Button active={true} size="large" style={{ width: 50 }} />
          <Skeleton.Button active={true} size="large" style={{ width: 80 }} />
          <Skeleton.Button active={true} size="large" style={{ width: 50 }} />
          <Skeleton.Button active={true} size="large" style={{ width: 50 }} />
        </div>
        <div className=" flex flex-col md:grid mt-4 md:grid-cols-4 md:grid-rows-3">
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-4">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
          <div className="flex- flex-col mt-2">
            <Skeleton.Image
              active={true}
              size="large"
              style={{ width: 250, height: 250 }}
            />
            <div className="flex flex-col mt-4 gap-2">
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
              <Skeleton.Input
                active={true}
                size="large"
                style={{ width: 200 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoading;
