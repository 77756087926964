import {
  USER_REVIEW_REQUEST,
  USER_REVIEW_SUCCESS,
  USER_REVIEW_FAIL,
  USER_REVIEW_LIST_REQUEST,
  USER_REVIEW_LIST_SUCCESS,
  USER_REVIEW_LIST_FAIL,
  USER_REVIEW_PRODUCT_REQUEST,
  USER_REVIEW_PRODUCT_SUCCESS,
  USER_REVIEW_PRODUCT_FAIL,
} from "../constants/reviewConstant";

export const userReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REVIEW_REQUEST:
      return { loading: true };
    case USER_REVIEW_SUCCESS:
      return { loading: false, success: true, error: "" };
    case USER_REVIEW_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
export const userReviewListReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REVIEW_LIST_REQUEST:
      return { loading: true };
    case USER_REVIEW_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        reviewProductList: action.payload.data,
        error: "",
      };
    case USER_REVIEW_LIST_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
export const userReviewProductReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REVIEW_PRODUCT_REQUEST:
      return { loading: true };
    case USER_REVIEW_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: true,
        reviewProductList: action.payload.data,
        error: "",
      };
    case USER_REVIEW_PRODUCT_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
