import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailReducer,
} from "../src/reducer/productReducer";
import { cartReducer } from "../src/reducer/cartReducer";
import {
  userLoginReducer,
  userRegisterReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
} from "../src/reducer/userReducer";
import {
  userReviewReducer,
  userReviewListReducer,
  userReviewProductReducer,
} from "../src/reducer/reviewReducer";
import { shippingFeeReducer } from "../src/reducer/shippingFeeReducer";
import { categoryListReducer } from "./reducer/categoryReducer";
import { orderDetailReducer } from "./reducer/viewOrderReducer";
import { storeListReducer } from "../src/reducer/storeReducer";
const rootReducer = combineReducers({
  productList: productListReducer,
  cartProductList: cartReducer,
  shippingFee: shippingFeeReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  categoryList: categoryListReducer,
  productDetail: productDetailReducer,
  userForgotPassword: userForgotPasswordReducer,
  userReview: userReviewReducer,
  userResetPassword: userResetPasswordReducer,
  userReviewList: userReviewListReducer,
  userReviewProduct: userReviewProductReducer,
  orderDetail: orderDetailReducer,
  storeList: storeListReducer,
});

const userInfoFromStorage =
  localStorage.getItem("userInfo") &&
  localStorage.getItem("userInfo") !== undefined
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
