import axios from "axios";
// Action Types
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAIL = "CHECKOUT_FAIL";

axios.defaults.baseURL = process.env.BASE_URL;

const handleCheckoutAPIWithUserId = async (
  userId,
  orderItems,
  shipment,
  totalAmount,
  note,
  isCod
) => {
  const requestBody = {
    user_id: userId,
    orderItems: orderItems,
    shipment: shipment,
    note: note,
    payment: {
      payment_method: "CASH",
    },
    total_amount: totalAmount,
    isCod: isCod,
  };

  const { data } = await axios.post("/api/v1/user/order", requestBody);

  return data;
};
const handleCheckoutAPIWithGuestInfo = async (
  guestUserInfo,
  orderItems,
  shipment,
  totalAmount,
  note,
  isCod
) => {
  const requestBody = {
    guest_user_info: guestUserInfo,
    orderItems: orderItems,
    shipment: shipment,
    note: note,
    payment: {
      payment_method: "CASH",
    },
    total_amount: totalAmount,
    isCod: isCod,
  };
  const { data } = await axios.post("/api/v1/user/order", requestBody);
  return data;
};

// Action Creator for fetching shipping fee
export const checkoutAction =
  (userInfo, orderItems, shipment, totalAmount, note) => async (dispatch) => {
    dispatch({ type: CHECKOUT_REQUEST });
    try {
      let objectResult = {};
      if (typeof userInfo === "number") {
        objectResult = await handleCheckoutAPIWithUserId(
          userInfo,
          orderItems,
          shipment,
          totalAmount,
          note
          // isCod
        );
      } else {
        objectResult = await handleCheckoutAPIWithGuestInfo(
          userInfo,
          orderItems,
          shipment,
          totalAmount,
          note
        );
      }
      dispatch({
        type: CHECKOUT_SUCCESS,
        payload: objectResult.data.AhamoveShippingFee,
      });
    } catch (error) {
      dispatch({
        type: CHECKOUT_FAIL,
        payload: error.response ? error.response.data : "Failed to checkout",
      });
    }
  };
