import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Typography } from "antd";

import { badge } from "../../../assets/images/index";
import Item from "./item/Item";

const DetailProduct = ({ data }) => {
  return (
    <>
      <Row className=" my-10 grid grid-cols-2 md:grid-cols-4 items-stretch">
        {data?.map((item, index) => (
          <Col key={index}>
            <Link to={`/detail/${item?.id}`}>
              <Item item={item} />
            </Link>
            {item.eventTitle && (
              <div className="flex items-center justify-center absolute top-0 left-20 w-full h-full">
                <Typography className="text-white text-lg absolute">
                  {item.eventTitle}
                </Typography>
                <img src={badge} alt="badge" />
              </div>
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default DetailProduct;
