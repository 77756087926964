import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  const footerContent = [
    {
      id: 2,
      title: "Store",
      submenu: [
        {
          title: t("Find a store"),
          path: `/contact`,
        },
      ],
    },
    {
      id: 3,
      title: t("Event"),
      submenu: [
        {
          title: t("Event"),
          path: `/event`,
        },
      ],
    },
    {
      id: 4,
      title: t("Brand story"),
      submenu: [
        {
          title: t("Brand"),
          path: `/about`,
        },
      ],
    },
    {
      id: 5,
      title: t("Customer service center"),
      submenu: [
        // {
        //   title: "Youtube",
        //   path: `/`,
        // },
        {
          title: "Facebook",
          path: `https://www.facebook.com/Mr.PieSaigon/`,
        },
        {
          title: "Instagram",
          path: `https://www.instagram.com/mrpiesaigon/`,
        },
      ],
    },
  ];

  return (
    <footer className="bg-[#2b0200] text-white text-2xl">
      <div className="md:flex md:justify-center md:items-center sm:px-12 px-4 bg-[#ffffff19] py-7">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 ml-4">
          {footerContent.map((el, index) => (
            <div key={el.id}>
              <h1 className="font-bold text-2xl mb-4">{t(el.title)}</h1>
              <div className="cursor-pointer">
                {el.submenu.map((e, i) => (
                  <Link key={i} to={e.path} className="text-white">
                    <span className="block mb-2 pl-1">
                      <span className="text-sm">{e.title}</span>
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
