import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { defaultImage } from "../../../assets/images";

const CustomTable = ({ orderItems, languageChange }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: <span className="font-bold text-lg">{t("PRODUCT")}</span>,
      dataIndex: "product",
      key: "product",
      render: (text, record) => (
        <div className="md:flex items-center ">
          <img
            src={record?.image ? record?.image : defaultImage}
            alt={t("PRODUCT_IMAGE")}
            className="w-24 h-24 mr-4 object-cover rounded-xl shadow-md"
          />
          <span className="text-lg text-left font-semibold md:text-base">
            {languageChange === "en" ? record.name_en : record.name}
            {record?.type === 1 && (
              <span className="text-white flex bg-[#5f76cb] md:mx-6 justify-center p-1 rounded-md">
                {t("Cold")}
              </span>
            )}
            {record?.type === 2 && (
              <span className="text-white flex bg-[#9a302e] md:mx-8 justify-center p-1 rounded-md">
                {t("Hot")}
              </span>
            )}
          </span>
        </div>
      ),
    },
    {
      title: <span className="font-bold text-lg">{t("SIZE")}</span>,
      dataIndex: "size",
      key: "size",
      align: "center",
      render: (size) => (
        <span className="bg-red-500 px-4 py-2 font-bold text-white rounded-xl shadow-md">
          {size}
        </span>
      ),
    },
    {
      title: <span className="font-bold text-lg">{t("Quantity")}</span>,
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (quantity) => (
        <span className="text-center text-lg font-semibold md:text-base">
          {quantity}
        </span>
      ),
    },
    {
      title: <span className="font-bold text-lg ">{t("Price")}</span>,
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (price) => (
        <span className="md:text-lg font-semibold text-base">
          {" "}
          {`${price.toLocaleString()} VND`}
        </span>
      ),
    },
  ];

  const data = orderItems?.map((item) => ({
    key: item.id,
    name: item.product.name,
    name_en: item.product.name_en,
    image: item.product.image,
    size: item.attribute.product_size,
    quantity: item.quantity,
    price: item.attribute.product_price,
    type: item.type,
  }));
  const paginationConfig = {
    pageSize: 2,
    position: ["bottomRight"],
    showSizeChanger: false,
  };
  return (
    <Table
      columns={columns}
      dataSource={data}
      className="shadow-md rounded-xl"
      pagination={paginationConfig}
    />
  );
};

export default CustomTable;
