// User Login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

// User Registration
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

// User Forgot
export const USER_FORGOT_REQUEST = "USER_FORGOT_REQUEST";
export const USER_FORGOT_SUCCESS = "USER_FORGOT_SUCCESS";
export const USER_FORGOT_FAIL = "USER_FORGOT_FAIL";

// User Change
export const USER_CHANGE_REQUEST = "USER_CHANGE_REQUEST";
export const USER_CHANGE_SUCCESS = "USER_CHANGE_SUCCESS";
export const USER_CHANGE_FAIL = "USER_CHANGE_FAIL";


