import {
    SHIPPING_FEE_REQUEST,
    SHIPPING_FEE_SUCCESS,
    SHIPPING_FEE_FAIL,
  } from '../actions/shippingFeeAction';
  const shippingFeeFromStorage = localStorage.getItem("shippingFee")
  ? localStorage.getItem("shippingFee")
  : 0; 
  const initialState = {
    loading: false,
    Fee: shippingFeeFromStorage,
    error: null,
  };
  
  export const shippingFeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHIPPING_FEE_REQUEST:
              return { ...state, loading: true };
        case SHIPPING_FEE_SUCCESS:
              return { ...state, loading: false, Fee: action.payload };
        case SHIPPING_FEE_FAIL:
              return { ...state, loading: false, error: action.payload };    
        default:
            return state;
    }
  };