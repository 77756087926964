import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { RightOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import promotion from "../../../assets/images/promotion.jpg";
import bg_promotion from "../../../assets/images/bg-promotion.jpg";
import { formatPrice, formatMoney } from "../../../utils/helpers";

const Promotion = () => {
  const { t } = useTranslation();
  const [isDevelop, setIsDevelop] = useState(true);
  // Danh sách sản phẩm
  const products = [
    {
      id: 1,
      name: "Combo Whopper JR + Drink",
      description: "Burger Bò Nướng Whopper JR + 1 nước ngọt + 1 Burger tặng",
      price: 100000,
    },
    {
      id: 2,
      name: "Combo Gà + Drink",
      description: "Burger Gà Phô Mai + 1 nước ngọt + 1 Burger tặng",
      price: 200000,
    },
    {
      id: 3,
      name: "Combo Bò Phô Mai + Drink",
      description: "Burger Bò Phô Mai + 1 nước ngọt + 1 Burger tặng",
      price: 150000,
    },
    {
      id: 4,
      name: "Combo Bò Hành Chiên + Drink",
      description: "Burger Bò Nướng Hành Chiên + 1 nước ngọt + 1 Burger tặng",
      price: 120000,
    },
    {
      id: 5,
      name: "Combo Cá + Drink",
      description: "Burger Cá + 1 nước ngọt + 1 Burger tặng",
      price: 130000,
    },
    {
      id: 6,
      name: "Combo Cá + Drink",
      description: "Burger Cá + 1 nước ngọt + 1 Burger tặng",
      price: 160000,
    },
  ];

  return (
    <>
      <div
        className="w-full font-MonaSans text-lg mt-12 md:mt-24"
        style={{
          // backgroundImage: `url(${bg_promotion})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="breadcrumb-container bg-black text-white p-4">
          <Link to="/" className="text-white hover:text-gray-300 mr-3">
            {t("Home")}
          </Link>
          <RightOutlined className="text-sm mr-3" />
          <Link to="/event" className="text-white mr-3">
            {t("Event")}
          </Link>
          <RightOutlined className="text-sm mr-3" />
          <strong>
            <Link to="/promotion" className="text-white">
              {t("Detail")}
            </Link>
          </strong>
        </div>
      </div>
      {isDevelop ? (
        <span className="text-2xl flex items-center justify-center font-bold pt-4">
          {t(`we don’t currently have any ongoing sale events or discounts.`)}
        </span>
      ) : (
        <div>
          <div className="intro-container grid md:grid-cols-2 font-AlfaSlabOne">
            <div className=" pr-2 ml-4 md:mt-10 lg:ml-20  text-base  italic ">
              <div className="text-left justify-between ">
                <h2 className="text-[#e7dfdf]  border-2 bg-[#ed7e42] flex justify-center items-center h-10 lg:mx-20">
                  ƯU ĐÃI MUA COMBO TẠI MR.PIE
                </h2>
                <p className="mt-4 mb-4">
                  Khách hàng khi mua hàng tại các cửa hàng Burger King hoặc đặt
                  hàng thông qua Hotine 1900 6960 hoặc qua Website
                  https://mrpie.vn/ sẽ nhận được ưu đãi : Mua 1 combo ( 1 bánh +
                  1 nước ) được tặng 1 bánh burger cỡ vừa
                </p>
                <h3 className="mb-4">Bánh tặng:</h3>
                <ul className=" ml-4">
                  <li className="mb-4">Burger bò nướng Whopper Jr</li>
                  <li className="mb-4">Burger gà phô mai</li>
                </ul>
                <p>
                  Áp dụng toàn hệ thống các cửa hàng Burger King (trừ Burger
                  King Sân bay).
                </p>
                <p>
                  Áp dụng với khách hàng mua tại cửa hàng hoặc đặt hàng thông
                  qua Hotline 1900 6960 hoặc qua Website{" "}
                  <a href="/">https://mprie.vn/</a>.
                </p>
                <p>Không áp dụng vào các ngày Lễ Tết.</p>
              </div>
            </div>

            <div className=" md:h-2/5 lg:h-2/3 md:flex md:justify-between ">
              <img
                src={promotion}
                alt="image"
                className=" mt-5 md:ml-10 md:mt-10 mb-4"
              />
            </div>
          </div>
          <div className=" justify-center text-xl">
            <motion.h2
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5 }}
              className="col-span-3 text-[#e7dfdf] border-b-2 rounded-xl border-[#ed7e42] bg-[#ed7e42] text-center py-2 ml-8 mr-8"
            >
              ĐẶT HÀNG NGAY BÂY GIỜ
            </motion.h2>
            <div className="flex justify-center mt-8  font-AlfaSlabOne">
              <div className="grid items-center mx-auto mb-6">
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1.5 }}
                  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center  ml-10 mr-10"
                >
                  {products.map((product) => (
                    <div
                      key={product.id}
                      className="bg-white border rounded-lg p-4"
                    >
                      <img
                        src={promotion}
                        alt="product"
                        className="w-full mb-2"
                      />
                      <p className="font-semibold mb-1 hover:text-[#d67f7f]">
                        {product.name}
                      </p>
                      <p className="text-sm mb-1 text-[#b39696]">
                        {product.description}
                      </p>
                      <p className="font-bold text-red-500">
                        {formatMoney(formatPrice(product.price))} VND
                      </p>
                      <button
                        icon={<ShoppingCartOutlined />}
                        className="w-2/3 mt-2 shadow-lg"
                      >
                        <Link
                          to="/cart"
                          className="
                    text-white 
                    flex 
                    items-center 
                    justify-center
                    font-semibold
                    px-6 py-2 
                    rounded-3xl
                    relative overflow-hidden 
                  bg-[#e27e44] 
                    shadow-lg 
                    transition-all 
                    before:absolute 
                    before:bottom-0 
                    before:left-0 
                    before:top-0 
                    before:z-0 
                    before:h-full 
                    before:w-0
                  before:bg-orange-700 
                    before:transition-all 
                    before:duration-500 
                  hover:text-white 
                  hover:shadow-orange-700 
                    hover:before:left-0 
                    hover:before:w-full
                    text-lg"
                        >
                          <span className="relative">{t("AddToCart")}</span>
                        </Link>
                      </button>
                    </div>
                  ))}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Promotion;
