import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_FORGOT_REQUEST,
  USER_FORGOT_SUCCESS,
  USER_FORGOT_FAIL,
  USER_CHANGE_REQUEST,
  USER_CHANGE_SUCCESS,
  USER_CHANGE_FAIL,
} from "../constants/userConstant";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        success: true,
        userInfo: action.payload,
        error: "",
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, error: "" };
    case USER_REGISTER_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGOT_REQUEST:
      return { loading: true };
    case USER_FORGOT_SUCCESS:
      return { loading: false, success: true, error: "" };
    case USER_FORGOT_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CHANGE_REQUEST:
      return { loading: true };
    case USER_CHANGE_SUCCESS:
      return { loading: false, success: true, error: "" };
    case USER_CHANGE_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
