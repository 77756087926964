import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { Link } from "react-router-dom";
// import CustomSlider from "./custom-slider";
import BreadCard from "./bread-card";
import SideBar from "./side-bar";
import SkeletonLoading from "./Skeleton";

import { Input } from "antd";
// import { Alert } from "antd";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";

import { listCategory } from "../../../actions/categoryAction";
import { reviewList } from "../../../actions/reviewAction";
import { listProduct } from "../../../actions/productAction";

import "./menu.css";

const Menu = () => {
  localStorage.removeItem("guestUserInfo");
  localStorage.removeItem("shippingFee");
  localStorage.removeItem("totalProductPrice");
  const [isOpen, setIsOpen] = useState(false);
  const [languageChange, setLanguageChange] = useState(i18n.language);
  const [selectedCate, setSelectedCate] = useState("");
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const productList = useSelector((state) => state.productList);
  const { loading } = productList;
  const reviewProductData = useSelector((state) => state.userReviewList);
  const categoryList = useSelector((state) => state.categoryList?.categoryList);
  useEffect(() => {
    dispatch(listCategory());
  }, []);
  useEffect(() => {
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);
  const onOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleOnClick = (id) => {
    setSelectedCate(id);
  };
  useEffect(() => {
    if (selectedCate === 0) {
      const params = {
        keyword: keyword.trim(),
        category_id: "",
      };
      dispatch(listProduct(params));
    } else {
      const params = {
        keyword: keyword.trim(),
        category_id: selectedCate,
      };
      dispatch(listProduct(params));
    }
    dispatch(reviewList());
  }, [dispatch, selectedCate, keyword]);
  const [term, setTerm] = useState("");

  const handleSearch = () => {
    setKeyword(term.trim());
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setKeyword(term.trim());
    }
  };
  categoryList?.sort((a, b) => a.name?.length - b.name?.length);

  // const [categoryOffset, setCategoryOffset] = useState(0);
  // const handleNextCategories = () => {
  //   setCategoryOffset(categoryOffset + 1);
  // };

  // const handlePrevCategories = () => {
  //   if (categoryOffset > 0) {
  //     setCategoryOffset(categoryOffset - 1);
  //   }
  // };
  return (
    <div className="relative md:static  w-full justify-center h-full lg:pt-10 ">
      {/* Sidebar -search - ratio button - tag*/}
      <div className=" flex bg-[#F8F8F8] text-black shadow-md mt-12 md:mt-24 p-4 px-4 pl-24">
        <Link to="/">
          <p className="text-xl text-black font-normal ">{t("Home")}</p>
        </Link>
        <RightOutlined className="text-sm mx-3" />

        <p className="text-xl text-black font-bold ">{t("Menu")}</p>
      </div>

      <div className="flex flex-row">
        {/**Side bar */}

        <div
          className={`absolute md:static md:hidden  h-full md:h-auto pb-2 w-full z-30 md:w-1/5 bg-[#F5F3F0] pr-1 ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <SideBar
            isOpen={isOpen}
            SetIsOpen={setIsOpen}
            keyword={keyword}
            setKeyword={setKeyword}
            selectedCate={selectedCate}
            setSelectedCate={setSelectedCate}
          />
        </div>

        {/* main screen*/}
        <div className="w-full">
          {/* event slider*/}
          {/* <div className=" w-full"><CustomSlider /></div> */}
          {/* container*/}
          <div className="md:flex justify-end items-center font-semibold text-md hidden  md:mr-8 md:mt-4 mt-2"></div>

          {loading ? (
            <div className="md:ml-8 md:mt-3">
              <SkeletonLoading />
            </div>
          ) : (
            <div className="w-full flex flex-col ">
              {/**Category list */}
              <div className="px-3 w-full justify-start md:pl-24 flex gap-4 flex-wrap">
                <span
                  onClick={() => handleOnClick(0)}
                  key={0}
                  className={`md:flex hidden w-20 justify-center items-center  hover:text-white cursor-pointer hover:bg-red-400 hover:border-white border-2 line-clamp-1 mt-1 mb-3 border-black rounded-3xl text-xl font-bold p-3 flex-shrink-0 pr-2 pl-2 ${
                    selectedCate === 0
                      ? "text-white border-white bg-red-400"
                      : ""
                  }`}
                >
                  {t(`All`)}
                </span>
                {categoryList?.map((el, index) => (
                  <span
                    onClick={() => handleOnClick(el?.id)}
                    key={index}
                    className={`md:block hidden hover:text-white cursor-pointer  hover:bg-red-400 hover:border-white border-2 line-clamp-1 mt-1 mb-3 border-black rounded-3xl text-xl font-bold p-3 flex-shrink-0 pr-2 pl-2 ${
                      selectedCate === el?.id
                        ? "text-white border-white bg-red-400"
                        : ""
                    }`}
                  >
                    {languageChange === "en" ? el?.name_en : el?.name}
                  </span>
                ))}
              </div>
              <div className="flex md: justify-between items-center">
                <div className="md:hidden mt-4 ml-4 md:ml-6 md:pl-4">
                  <button
                    onClick={onOpen}
                    className="border border-black text-black p-2  text-xl rounded-xl font-semibold"
                  >
                    {t('Menu')}
                  </button>
                </div>
                <div className="hidden md:block"></div>
                {/**Search */}
                <div className="justify-end flex md:mr-8  pr-14  ">
                  <div className=" right-0 mt-5 md:w-64 w-40  ml-3">
                    <Input
                      size="large"
                      placeholder={t("Search by name...")}
                      className="rounded-3xl py-0  ml-4 h-8  font-semibold text-xl"
                      value={term.trim()}
                      onChange={(e) => setTerm(e.target.value.trim())}
                      onPressEnter={handleKeyPress}
                      suffix={<SearchOutlined onClick={handleSearch} />}
                    />
                  </div>
                </div>
              </div>

              <div className=" lg:mx-10 lg:px-8 px-10 pt-3">
                <BreadCard
                  data={productList?.productList}
                  review={reviewProductData?.reviewProductList}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};
export default Menu;
