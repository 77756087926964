import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatPrice, formatMoney } from "../../../utils/helpers";
import i18n from "../../../i18n";
import {
  checkOrderHistory,
  UpdateOrderStatus,
} from "../../../services/order/orderService";
import { useParams } from "react-router-dom";
import { fetchOrderDetailAction } from "../../../actions/viewOrderAction";
import { addToCart } from "../../../actions/addToCartAction";
import CustomTable from "./custom-table";
import { format } from "date-fns";
import { Modal } from "antd";
const OrderDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const orderDetailInfo = useSelector((state) => state.orderDetail.data);
  const params = useParams();
  const { orderId } = params;

  const [orderStatus, setOrderStatus] = useState(orderDetailInfo?.status);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [languageChange, setLanguageChange] = useState(i18n.language);
  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return t("PENDING");
      case 1:
        return t("PAID");
      case 2:
        return t("COMPLETE");
      case 3:
        return t("CANCELED");
      case 4:
          return t("SHIPPING");    
      default:
        return "";
    }
  };

  const handleCancel = async () => {
    setIsUpdating(true);
    try {
      await UpdateOrderStatus(orderDetailInfo?.id, 3, userInfo.data.token);
      setOrderStatus(3);
      setIsUpdating(false);
      setIsModalVisible(false);
    } catch (error) {
      // console.error("Error updating order status:", error);
    }
  };

  function getNewCartUIId() {
    const lastId = parseInt(localStorage.getItem("lastCartIdUI")) || 0;
    const newId = lastId + 1;
    localStorage.setItem("lastCartIdUI", newId);
    return newId;
  }
  const handleEdit = async (order) => {
    let updatedCartItems = [];
    for (const orderItem of order.orderItems) {
      const IdUI = getNewCartUIId();
      const newItem = {
        IdUI,
        id: orderItem?.product.id,
        name: orderItem?.product?.name,
        name_en: orderItem?.product?.name_en,
        quantity: orderItem?.quantity,
        attribute_id: orderItem?.attribute_id,
        price: orderItem?.attribute?.product_price,
        size: orderItem?.attribute?.product_size,
        category: orderItem?.product?.category.name,
        category_en: orderItem?.product?.category?.name_en,
        image: orderItem?.product?.image,
        type: orderItem?.type,
        orderId: order.id,
      };
      updatedCartItems.push(newItem);
    }
    localStorage.setItem("cartProducts", JSON.stringify(updatedCartItems));
    dispatch(addToCart(updatedCartItems));
    localStorage.setItem("cartCount", updatedCartItems?.length);
    window.dispatchEvent(
      new CustomEvent("cartUpdated", { detail: updatedCartItems?.length })
    );
    window.location.href = "/cart";
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch(fetchOrderDetailAction(userInfo.data.token, orderId));
    const handleLanguageChange = (event) => {
      setLanguageChange(event.detail);
    };
    window.addEventListener("languageChanged", handleLanguageChange);
    return () => {
      window.removeEventListener("languageChanged", handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    if (orderDetailInfo?.status !== orderStatus) {
      setOrderStatus(orderDetailInfo?.status);
    }
  }, [orderDetailInfo?.status]);

  return (
    <div className=" relative font-roboto mt-12 lg:mt-24 px-0 md:px-44 min-h-screen bg-[#F2EBE6] ">
      <h1 className="my-12 mx-auto pt-10 font-AlfaSlabOne text-2xl text-center">
        <span className="border-b border-black">{t("ORDER DETAIL")}</span>
      </h1>
      {orderDetailInfo?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <h2 className="text-lg">{t("The order has been deleted because all the products inside it have been deleted.")}</h2>
              </div>
      ) : (
            <>
                <CustomTable
                  orderItems={orderDetailInfo?.orderItems}
                  languageChange={languageChange}
                />
                <div className="text-right border-b border-gray-300 mt-4 mx-8 justify-end">
                  <p className="text-gray-600 font-semibold font-roboto mb-6 ml-4 text-right">
                    {t("Order time")}:{" "}
                    {orderDetailInfo?.updatedAt
                      ? format(new Date(orderDetailInfo?.updatedAt), "HH:mm:ss dd/MM/yy")
                      : ""}
                  </p>
                  <p className="text-gray-600 font-semibold font-roboto mb-6 ml-4 text-right">
                    {t("Status")}: {getStatusLabel(orderStatus)}
                  </p>
                  <p className=" text-base  mb-4">
                    {t("Shipping fee")} :
                    <span className=" text-[#f36406f8] text-lg  ml-2  ">
                      {formatMoney(orderDetailInfo?.shipment?.fee)} VND
                    </span>
                  </p>
                  <p className=" text-base font-AlfaSlabOne mb-4">
                    {t("Total amount")} :
                    <span className=" text-[#f36406f8] text-lg font-bold ml-2 font-AlfaSlabOne ">
                      {formatMoney(parseFloat(orderDetailInfo?.total_amount))} VND
                    </span>
                  </p>
                  {getStatusLabel(orderStatus) === t("PENDING") && (
                    <button
                      disabled={isUpdating}
                      className="px-4 py-2 text-lg bg-red-400 text-white hover:bg-gray-500 shadow-lg rounded-md mr-2 mb-4"
                      onClick={showModal}
                    >
                      {t("Cancel")}
                    </button>
                  )}
                  <Modal
                    title={t("ConfirmCancel")}
                    visible={isModalVisible}
                    onOk={handleCancel}
                    onCancel={handleClose}
                    okText={t("Confirm")}
                    cancelText={t("Cancel")}
                  >
                    <p>{t("DeleteOrder")}</p>
                  </Modal>
                  {/* {getStatusLabel(orderStatus) === t("PENDING") && (
                    <button
                      disabled={isUpdating}
                      className="px-4 py-2 text-lg bg-red-400 text-white hover:bg-gray-500 shadow-lg rounded-md mr-2 mb-4"
                      onClick={() => handleEdit(orderDetailInfo)}
                    >
                      {t("Edit")}
                    </button>
                  )} */}
                </div>
            </>
      )}
    </div>
  );
};

export default OrderDetail;
