import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import ShoppingCartSummary from "./shopping-cart-summary";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../actions/addToCartAction";
import { formatMoney } from "../../../utils/helpers";
import { checkExistenceProductList } from "../../../services/product/productService";
// import Loading from "../loading/Loading";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

const CartDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialProducts = useSelector(
    (state) => state.cartProductList.products
  );
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const isLoggedIn = userInfo?.success ? userInfo?.success : false;
  const [cartState, setCartState] = useState({
    products: initialProducts ? initialProducts : [],
    productCount: initialProducts?.length ? initialProducts?.length : 0,
    showCartEmptyAlert: false,
    showConfirmModal: false,
  });
  const computeTotalPrice = (cartProducts) => {
    let totalPrice = 0;
    cartProducts?.forEach((product) => {
      totalPrice += product.price * product.quantity;
    });
    return totalPrice;
  };
  const [totalPrice, setTotalPrice] = useState(
    computeTotalPrice(initialProducts)
  );
  useEffect(() => {
    if (initialProducts) {
      setCartState({
        products: initialProducts,
        productCount: initialProducts?.length,
        showCartEmptyAlert: initialProducts?.length === 0,
        showConfirmModal: false,
      });
      setTotalPrice(computeTotalPrice(initialProducts));
    }
  }, [initialProducts]);
  const handleRemoveProduct = (productUIId) => {
    const updatedProducts = cartState.products.filter(
      (product) => product.IdUI !== productUIId
    );
    const totalPrice = computeTotalPrice(updatedProducts);
    window.dispatchEvent(
      new CustomEvent("cartUpdated", { detail: updatedProducts?.length })
    );
    localStorage.setItem("cartProducts", JSON.stringify(updatedProducts));
    localStorage.setItem("cartCount", updatedProducts?.length);
    dispatch(addToCart(updatedProducts));
    setCartState((prevState) => ({
      ...prevState,
      products: updatedProducts,
      productCount: updatedProducts?.length,
    }));
    setTotalPrice(totalPrice);
  };
  const handleRemoveAllProducts = () => {
    localStorage.removeItem("cartProducts");
    localStorage.removeItem("cartCount");
    localStorage.removeItem("lastCartIdUI");
    window.dispatchEvent(new CustomEvent("cartUpdated", { detail: 0 }));
    setCartState((prevState) => ({
      ...prevState,
      products: [],
      productCount: 0,
      showConfirmModal: false,
      showCartEmptyAlert: false,
    }));
    setTotalPrice(0);
  };
  const handleCartWhenProductsDeleted = async (
    cartItems,
    deletedProducts,
    deletedAttributeProducts
  ) => {
    let updatedCartItems = cartItems;
    if (deletedProducts?.length > 0) {
      updatedCartItems = cartItems.filter(
        (cartItem) =>
          !deletedProducts.some(
            (deletedProduct) => deletedProduct.id === cartItem.id
          )
      );
    }
    if (deletedAttributeProducts?.length > 0) {
      updatedCartItems = cartItems.filter(
        (cartItem) =>
          !deletedAttributeProducts.some(
            (deletedAttributeProduct) =>
              deletedAttributeProduct.id === cartItem.id &&
              deletedAttributeProduct.attribute_id === cartItem.attribute_id
          )
      );
    }
    localStorage.setItem("cartProducts", JSON.stringify(updatedCartItems));
    window.dispatchEvent(
      new CustomEvent("cartUpdated", { detail: updatedCartItems?.length })
    );
    localStorage.setItem("cartCount", updatedCartItems?.length);
    dispatch(addToCart(updatedCartItems));
    setCartState((prevState) => ({
      ...prevState,
      products: updatedCartItems,
      productCount: updatedCartItems?.length,
    }));
  };
  function updateCartItemsWhenProductsUpdated(cartItems, existProducts) {
    cartItems?.forEach((cartItem) => {
      const matchingProduct = existProducts.find(
        (product) =>
          product?.id === cartItem?.id &&
          product?.attribute_id === cartItem?.attribute_id
      );
      if (matchingProduct) {
        cartItem.attribute_id = matchingProduct.attribute_id;
        cartItem.category = matchingProduct.category;
        cartItem.id = matchingProduct.id;
        cartItem.image = matchingProduct.image;
        cartItem.name = matchingProduct.name;
        cartItem.price = matchingProduct.price;
        cartItem.size = matchingProduct.size;
      }
    });
    localStorage.setItem("cartProducts", JSON.stringify(cartItems));
    dispatch(addToCart(cartItems));
    setCartState((prevState) => ({
      ...prevState,
      products: cartItems,
    }));
  }
  const handleCheckout = async () => {
    if (cartState?.products?.length === 0) {
      return;
    } else if (cartState?.products?.length > 0) {
      const fetchProductExistence = async () => {
        const response = await checkExistenceProductList(initialProducts);
        localStorage.setItem(
          "existProducts",
          JSON.stringify(response?.data?.existProducts)
        );
        if (response?.data?.deleted === true) {
          const cartItems = initialProducts;
          const deletedProducts = response?.data?.data?.deletedProducts;
          const deletedAttributeProducts =
            response?.data?.data?.deletedAttributeProducts;
          handleCartWhenProductsDeleted(
            cartItems,
            deletedProducts,
            deletedAttributeProducts
          );
        }
      };
      await fetchProductExistence();
      const cartProductFromStorage = localStorage.getItem("cartProducts")
        ? JSON.parse(localStorage.getItem("cartProducts"))
        : null;
      const existProducts = localStorage.getItem("existProducts")
        ? JSON.parse(localStorage.getItem("existProducts"))
        : null;
      if (cartProductFromStorage?.length > 0) {
        updateCartItemsWhenProductsUpdated(
          cartProductFromStorage,
          existProducts
        );
        if (!isLoggedIn) {
          window.location.href = "/guest_info";
        } else {
          setTimeout(() => {
            window.location.href = "/checkout";
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    if (initialProducts?.length > 0) {
      if (initialProducts[0]?.checkedOut === true) {
        localStorage.setItem("cartProducts", "[]");
        localStorage.removeItem("cartCount");
        localStorage.removeItem("lastCartIdUI");
        localStorage.removeItem("shippingFee");
        localStorage.removeItem("guestUserInfo");
        window.dispatchEvent(new CustomEvent("cartUpdated", { detail: 0 }));
      }
      const fetchProductExistence = async () => {
        const response = await checkExistenceProductList(initialProducts);
        if (response?.data?.deleted === true) {
          const cartItems = initialProducts;
          const deletedProducts = response?.data?.data?.deletedProducts;
          const deletedAttributeProducts =
            response?.data?.data?.deletedAttributeProducts;
          await handleCartWhenProductsDeleted(
            cartItems,
            deletedProducts,
            deletedAttributeProducts
          );
        }
        const cartProductFromStorage = localStorage.getItem("cartProducts")
          ? JSON.parse(localStorage.getItem("cartProducts"))
          : null;
        updateCartItemsWhenProductsUpdated(
          cartProductFromStorage,
          response?.data?.existProducts
        );
      };
      fetchProductExistence();
    }
    localStorage.removeItem("guestUserInfo");
    localStorage.removeItem("shippingFee");
    localStorage.removeItem("totalProductPrice");
  }, []);
  return (
    <div className="intro-container min-h-screen font-roboto mt-12 lg:mt-1 bg-[#F2EBE6] lg:pt-9 ">
      <div className="flex  bg-[#F8F8F8] text-black shadow-md mt-12 md:mt-24 p-4 px-4 md:px-44">
        <Link to="/">
          <p className="text-xl text-black font-normal ">{t("Home")}</p>
        </Link>
        <RightOutlined className="text-sm mx-3" />

        <p className="text-xl text-black font-bold ">{t("Cart")}</p>
      </div>

      <div className="title-container px-0 md:px-44 mt-4">
        <div className="flex justify-between">
          <h1 className=" text-2xl">{t("ShoppingCart")}</h1>

          <div className=" justify-center  text-right text-xl font-bold flex items-center md:justify-end">
            <p className="mr-5">
              {cartState?.productCount} {t("Item")}
            </p>
            <Button
              className="bg-[#e44747] h-10 leading-3 text-white text-lg font-semibold rounded-xl shadow-md transition-colors duration-300 ease-in-out"
              onClick={() =>
                setCartState((prevState) => ({
                  ...prevState,
                  showConfirmModal: true,
                }))
              }
              hidden={cartState.products?.length === 0}
            >
              {t("DeleteAll")}
            </Button>
          </div>
        </div>

        <Modal
          title={t("Notification")}
          open={cartState.showConfirmModal}
          onCancel={() =>
            setCartState((prevState) => ({
              ...prevState,
              showConfirmModal: false,
            }))
          }
          footer={[
            <Button
              key="cancel"
              onClick={() =>
                setCartState((prevState) => ({
                  ...prevState,
                  showConfirmModal: false,
                }))
              }
            >
              {t("Cancel")}
            </Button>,
            <Button
              key="confirm"
              type="primary"
              danger
              onClick={handleRemoveAllProducts}
            >
              {t("Confirm")}
            </Button>,
          ]}
        >
          <p>{t("ConfirmDeleteAll")}</p>
        </Modal>

        {cartState?.products?.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-6 pt-6 pb-2 md:ml-10 md:mr-10 font-bold">
            <div className="hidden text-center sm:block">
              <h2>{t("DetailItems")}</h2>
            </div>
            <div className="hidden sm:block">
              <h2 className="text-center">{t("NameItem")}</h2>
            </div>
            <div className="hidden sm:block">
              <h2 className="text-center">{t("Category")}</h2>
            </div>

            <div className="hidden sm:block">
              <h2 className="text-center">{t("Quantity")}</h2>
            </div>
            <div className="hidden sm:block">
              <h2 className="text-center">{t("Price")}</h2>
            </div>
            <div className="hidden sm:block">
              <h2 className="text-center">{t("TotalCart")}</h2>
            </div>
          </div>
        )}

        {cartState.products?.length === 0 ? (
          <div className="empty-cart">
            <p className="mt-8 mb-4 ml-10 text-xl ">{t("EmptyCart")}</p>
            <p className="py-20 ml-10 mr-10 border-t-2 border-b-2"></p>
          </div>
        ) : (
          <ShoppingCartSummary
            products={initialProducts}
            onRemoveProduct={handleRemoveProduct}
            updateTotalPrice={setTotalPrice}
          />
        )}

        <div className="flex justify-center md:justify-end test-xl ">
          <div className="mr-4  flex-col items-center text-center font-JosefinSans font-bold">
            <p className="mb-2 text-center md:text-left ">
              {t("TempCart")}:
              <div className="text-xl flex items-center justify-center">
                <p>{formatMoney(totalPrice)} VND</p>
              </div>
            </p>
            <div className="mb-2">
              <p className="font-bold text-center md:text-left">
                {t("TotalCart")}:
              </p>
              <div className="bg-[#a0aec3] h-10 mt-2 text-white text-xl px-2 font-semibold rounded-xl shadow-md flex items-center justify-center">
                <p>{formatMoney(totalPrice)} VND</p>
              </div>
            </div>

            <Button
              disabled={cartState.products?.length === 0}
              onClick={handleCheckout}
              className=" bg-[#d73434] h-12 text-white text-lg font-semibold rounded-xl shadow-md transition-colors duration-300 ease-in-out"
            >
              {t("Order")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDetail;
