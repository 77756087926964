import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_FORGOT_REQUEST,
  USER_FORGOT_SUCCESS,
  USER_FORGOT_FAIL,
  USER_CHANGE_REQUEST,
  USER_CHANGE_SUCCESS,
  USER_CHANGE_FAIL,
} from "../constants/userConstant";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

//login
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //get login data
    const { data } = await axios.post(
      "/api/v1/user/login",
      { username, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    //set user info into local storage
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//logout
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};
//register an user
export const registerUser =
  (name, username, email, password, phone, address) =>
  async (dispatch, getState) => {
    const userType = 1;

    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      //get user from state
      const {
        userLogin: { userInfo },
      } = getState();

      //headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      //get login data
      const { data } = await axios.post(
        "/api/v1/user/signup",
        { name, username, userType, email, password, phone, address },
        config
      );
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error.response.data.error,
      });
    }
  };
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //get  data
    const { data } = await axios.post(
      "/api/v1/user/forgot_password ",
      { email },
      config
    );

    dispatch({
      type: USER_FORGOT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FORGOT_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const resetPassword = (newPassword, token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_CHANGE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //get  data
    const { data } = await axios.post(
      `/api/v1/user/reset_password?${token} `,
      { newPassword },
      config
    );
    dispatch({
      type: USER_CHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_CHANGE_FAIL,
      payload: error.response.data.message,
    });
  }
};
