import {
  USER_REVIEW_REQUEST,
  USER_REVIEW_SUCCESS,
  USER_REVIEW_FAIL,
  USER_REVIEW_LIST_REQUEST,
  USER_REVIEW_LIST_SUCCESS,
  USER_REVIEW_LIST_FAIL,
  USER_REVIEW_PRODUCT_REQUEST,
  USER_REVIEW_PRODUCT_SUCCESS,
  USER_REVIEW_PRODUCT_FAIL,
} from "../constants/reviewConstant";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

//register an user
export const reviewUser =
  (product_id, rating, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_REVIEW_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      //headers
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo?.data?.token}`,
        },
      };
      // console.log("check");
      //get login data
      const { data } = await axios.post(
        "/api/v1/user/review",
        { product_id, rating, comment },
        config
      );
      // console.log(data);
      dispatch({
        type: USER_REVIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REVIEW_FAIL,
        payload: error?.response?.data?.error,
      });
    }
  };

export const reviewProduct = (product_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_REVIEW_PRODUCT_REQUEST,
    });

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("check");
    //get login data
    const { data } = await axios.get(
      `/api/v1/user/review/${product_id}`,

      config
    );
    // console.log("data>>>", data);
    dispatch({
      type: USER_REVIEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REVIEW_PRODUCT_FAIL,
      payload: error?.response?.data?.error,
    });
  }
};
export const reviewList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_REVIEW_LIST_REQUEST,
    });
    // const {
    //   userLogin: { userInfo },
    // } = getState();

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("check");
    //get login data
    const { data } = await axios.get("/api/v1/user/review/list", config);
    // console.log(data);
    dispatch({
      type: USER_REVIEW_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REVIEW_LIST_FAIL,
      payload: error?.response?.data?.error,
    });
  }
};
