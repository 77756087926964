import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Event from "../menu/event";

import { motion } from "framer-motion";
import cupcake from "../../../assets/images/cupcake.png";
import bread from "../../../assets/images/bread.jpg";
import cookies from "../../../assets/images/cookies.png";

const EventList = () => {
  const { t } = useTranslation();
  const [isDevelop, setIsDevelop] = useState(false);
  localStorage.removeItem("guestUserInfo");
  localStorage.removeItem("shippingFee");
  localStorage.removeItem("totalProductPrice");
  return (
    <div className="grid items-center mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
        className="mt-4 lg:px-40 grid items-center grid-cols-1 lg:grid-cols-2 gap-10"
      >
        {!isDevelop ? (
          <span className="text-2xl">
            {t(`we don’t currently have any ongoing sale events or discounts.`)}
          </span>
        ) : (
          <>
            <Event image={cupcake} size={350} IsEvent={true} />
            <Event image={bread} size={350} IsEvent={true} />
            <Event image={cookies} size={350} IsEvent={true} />
            <Event image={bread} size={350} IsEvent={true} />
            <Event image={cookies} size={350} IsEvent={true} />
            <Event image={cupcake} size={350} IsEvent={true} />
            <Event image={bread} size={350} IsEvent={true} />
            <Event image={cupcake} size={350} IsEvent={true} />
            <Event image={cookies} size={350} IsEvent={true} />
          </>
        )}
      </motion.div>
    </div>
  );
};

export default EventList;
