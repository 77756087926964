import {
    ORDER_DETAIL_REQUEST,
    ORDER_DETAIL_SUCCESS,
    ORDER_DETAIL_FAIL,
  } from '../actions/viewOrderAction';
  const orderDetailFromStorage = localStorage.getItem("orderDetail")
  ? JSON.parse(localStorage.getItem("orderDetail"))
  : []; 
  const initialState = {
    loading: false,
    data: orderDetailFromStorage,
    error: null,
  };
  
  export const orderDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_DETAIL_REQUEST:
              return { ...state, loading: true };
        case ORDER_DETAIL_SUCCESS:
              return { ...state, loading: false, data: action.payload };
        case ORDER_DETAIL_FAIL:
              return { ...state, loading: false, error: action.payload };    
        default:
            return state;
    }
  };