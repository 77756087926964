// User Review
export const USER_REVIEW_REQUEST = "USER_REVIEW_REQUEST";
export const USER_REVIEW_SUCCESS = "USER_REVIEW_SUCCESS";
export const USER_REVIEW_FAIL = "USER_REVIEW_FAIL";

export const USER_REVIEW_LIST_REQUEST = "USER_REVIEW_LIST_REQUEST";
export const USER_REVIEW_LIST_SUCCESS = "USER_REVIEW_LIST_SUCCESS";
export const USER_REVIEW_LIST_FAIL = "USER_REVIEW_LIST_FAIL";

export const USER_REVIEW_PRODUCT_REQUEST = "USER_REVIEW_PRODUCT_REQUEST";
export const USER_REVIEW_PRODUCT_SUCCESS = "USER_REVIEW_PRODUCT_SUCCESS";
export const USER_REVIEW_PRODUCT_FAIL = "USER_REVIEW_PRODUCT_FAIL";
