import React from "react";
import "./dropdown.css";

import { Dropdown } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
const DropDownLanguage = ({ changeLanguage }) => {
  const items = [
    {
      key: "1",
      label: (
        <p
          className="font-semibold"
          onClick={() => {
            window.dispatchEvent(
              new CustomEvent("languageChanged", { detail: "en" })
            );
            changeLanguage("en");
          }}
        >
          English
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p
          className="font-semibold"
          onClick={() => {
            window.dispatchEvent(
              new CustomEvent("languageChanged", { detail: "vi" })
            );
            changeLanguage("vi");
          }}
        >
          Vietnamese
        </p>
      ),
    },
    // {
    //   key: "3",
    //   label: (
    //     <p
    //       className="font-semibold"
    //       onClick={() => {
    //         window.dispatchEvent(
    //           new CustomEvent("languageChanged", { detail: "kr" })
    //         );
    //         changeLanguage("kr");
    //       }}
    //     >
    //       Korean
    //     </p>
    //   ),
    // },
  ];

  return (
    <>
      <Dropdown menu={{ items }} placement="bottom" arrow>
        <GlobalOutlined className=" text-2xl text-black pb-1 pr-2" />
      </Dropdown>
    </>
  );
};

export default DropDownLanguage;
