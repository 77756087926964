import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_RESET,
} from "../constants/categoryConstant";

export const categoryListReducer = (
  state = { loading: true, categoryList: [] },
  action
) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true, categoryList: [] };
    case CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        categoryList: action.payload.data.categories,
        pageSize: action.payload.data.pages,
        page: action.payload.data.page,
      };
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORY_LIST_RESET:
      return { categoryList: [] };
    default:
      return state;
  }
};
