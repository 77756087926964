import axios from "axios";
import {
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAIL,
} from "../constants/storeConstant";

axios.defaults.baseURL = process.env.BASE_URL;

export const listStore = (query) => async (dispatch, getState) => {
  try {
    const { page, pageSize, sort, order } = query;

    const dpage = page ? page : "";
    const dpageSize = pageSize ? pageSize : "";
    const dsort = sort ? sort : "";
    const dorder = order ? order : "";

    dispatch({
      type: STORE_LIST_REQUEST,
    });

    const params = {
      page: dpage,
      pageSize: dpageSize,
      sort: dsort,
      order: dorder,
    };
    const { data } = await axios.get("/api/v1/admin/store/list", {
      params: params,
    });

    dispatch({
      type: STORE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STORE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
