import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { avatar } from "../../../assets/images";
import { renderStarFromNumber } from "../../../utils/helpers";

const Comment = ({ data, ratedScore }) => {
  const [showAllComments, setShowAllComments] = useState(false);
  const { t } = useTranslation();

  const handleShowMoreComments = () => {
    setShowAllComments(!showAllComments);
  };

  return (
    <div>
      {data?.reviews?.length > 2 && (
        <button
          className="mt-3 border p-2 bg-gradient-to-r from-[#db6c56] to-[#bb457a] rounded-lg text-white  hover:"
          onClick={handleShowMoreComments}
        >
          {showAllComments ? (
            <span className="text-white text-lg font-semibold">
              {t("Show Less")}
            </span>
          ) : (
            <span className="text-white text-lg font-semibold">
              {t("Show More")}
            </span>
          )}
        </button>
      )}
      {data?.reviews?.map((el, index) => {
        if (el.comment !== "" && (showAllComments || index < 2)) {
          return (
            <div
              className="w-full flex flex-col gap-2 rounded-xl bg-gray-200 p-2 pb-3 mt-3"
              key={index}
            >
              <div className="flex justify-between items-center gap-2 mt-2">
                <div className="flex items-center">
                  <img
                    src={avatar}
                    alt="User image"
                    className="w-10 h-10 rounded-full"
                  />
                  <span className="text-lg pl-4">
                    {el.user?.username ? el.user?.username : t("Deleted User")}
                  </span>
                  <span className="flex pl-2 pb-1 pr-2">
                    {el?.rating > 0 && renderStarFromNumber(el?.rating, 24)}
                  </span>
                </div>

                <span className="text-md  font-thin text-zinc-300  pr-4">
                  {new Date(el.createdAt).toLocaleString()}
                </span>
              </div>

              <div className="w-full  rounded-md p-2">
                <textarea
                  readOnly
                  className="w-full h-full p-2 focus:outline-none rounded-md text-lg text-black"
                  value={el.comment}
                />
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Comment;
