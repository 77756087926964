import axios from "axios";
// Action Types
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

axios.defaults.baseURL = process.env.BASE_URL;

const handleUpdateOrderApi = async (id, orderItems, shipment,totalAmount, note, token) => {
    const headers = {
        Authorization: `Bearer ${token}`    
    }
    const requestBody = {
        "orderItems":orderItems,
        "shipment":shipment,
        "note": note,
        "payment":{
            "payment_method":"CASH"
        },
        "total_amount":totalAmount
    };
    const config = {
        headers: headers
    };
    const {data} = await axios.patch(`/api/v1/user/order/${id}`, requestBody, config);
    
    return data;
};


// Action Creator for fetching shipping fee
export const updateOrderAction = (id, orderItems, shipment, totalAmount, note, token) => async (dispatch) => {
    console.log(shipment)
    dispatch({ type: UPDATE_ORDER_REQUEST });
    try {
        let objectResult ={}
        objectResult = await handleUpdateOrderApi(id, orderItems, shipment, totalAmount, note, token);
        
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: objectResult});
    } catch (error) {
        dispatch({
            type: UPDATE_ORDER_FAIL,
            payload: error.response ? error.response.data : "Failed to update order"
        });
    }
};
