import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
// import { Radio } from "antd";
import { Alert } from "antd";
// import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { motion } from "framer-motion";
import Loading from "../loading/Loading";
import { forgotPassword } from "../../../actions/userAction";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
  } = useForm();

  // localStorage.removeItem("guestUserInfo");
  // localStorage.removeItem("shippingFee");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ErrorNotice, setErrorNotice] = useState(false);
  const [SuccessNotice, setSuccessNotice] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const { success, error, loading } = useSelector(
    (state) => state.userForgotPassword
  );

  const [isCompleted, setIsCompleted] = useState(false);
  const onSubmit = async (data) => {
    const { email } = data;
    dispatch(forgotPassword(email));
    setIsCompleted(true);
  };

  useEffect(() => {
    if (isCompleted) {
      if (success === true) {
        setSuccessNotice(true);
        setErrorNotice(false);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else if (success === false) {
        setMessage(error);
        setErrorNotice(true);
        setSuccessNotice(false);
      }
    }
  }, [success, isCompleted, navigate]);
  useEffect(() => {
    if (SuccessNotice || ErrorNotice) {
      const timeout = setTimeout(() => {
        setSuccessNotice(false);
        setErrorNotice(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [SuccessNotice, ErrorNotice]);

  return (
    <div className="w-full h-dvh bg-gradient-to-r from-[#c97e6f] to-[#d55f94] flex justify-center items-start flex-grow">
      {loading && (
        <div className="absolute inset-0 z-80 flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-75"></div>
          <Loading />
        </div>
      )}
      <div
        className=" h-96 bg-white mx-auto md:max-w-xl w-4/5 mt-32
     text-base shadow-lg rounded-lg border-2 py-8 px-4 mb-10"
      >
        <div className="flex flex-col justify-center items-center">
          <label className="uppercase text-2xl   font-bold">
            {t("FORGOT PASSWORD")}
          </label>
          <span className="text-xl align-middle">
            {t(`Please enter your email address and we'll send you`)}
          </span>
        </div>
        <div className="pt-4">
          <form className="text-lg" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-gray-700 text-md font-bold mb-2">
                {t("Email")}
                <input
                  className=" mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  {...register("email", {
                    required: true,
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  })}
                />
              </label>
              {errors.email && (
                <div className="text-red-500">
                  <FontAwesomeIcon icon={faTimesCircle} />
                  <span className="ml-2 italic text-sm">
                    {t("Email valid (example@gmail.com)")}
                  </span>
                </div>
              )}
            </div>

            {/* Button submit */}
            <div className="flex justify-center items-center">
              <div className="w-full md:w-2/3">
                <button
                  className="w-full shadow bg-gradient-to-r from-[#db6c56] to-[#bb457a] hover:bg-[#1890FF] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
                  type="submit"
                >
                  {t("Get Password")}
                </button>
              </div>
            </div>
            <div className="mt-6 flex justify-center items-center pb-2">
              <p>
                {t("Don't have account ?")}
                <Link to="/register">
                  <span className="text-md ml-2 font-bold text-[#1890FF] cursor-pointer">
                    {t("CreateAccount")}
                  </span>
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute z-50">
        {SuccessNotice === true && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-10 right-2 md:right-10"
          >
            <Alert
              message={
                <span>
                  <CheckCircleOutlined style={{ color: "Green" }} />{" "}
                  {t("Success")}{" "}
                </span>
              }
              description={t(`Please Check your email`)}
              type="success"
              closable
              onClose={() => setSuccessNotice(false)}
            />
          </motion.div>
        )}
        {ErrorNotice === true && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-10 right-2 md:right-10"
          >
            <Alert
              message={
                <span>
                  <ExclamationCircleOutlined style={{ color: "red" }} />{" "}
                  {t("Error")}
                </span>
              }
              description={t("Email address is not exist")}
              type="error"
              closable
              onClose={() => setErrorNotice(false)}
            />
          </motion.div>
        )}
      </div>
      {/* <div className=" bg-white mx-auto mt-32  shadow-lg rounded-lg border-2 mb-14"> */}

      {/* </div> */}
    </div>
  );
};

export default ForgotPassword;
