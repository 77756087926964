// Action Types
export const CART_LIST_REQUEST = "CART_LIST_REQUEST";
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS";
export const CART_LIST_FAIL = "CART_LIST_FAIL";

// Action Creator for fetching cart products
export const listCartProduct = () => async (dispatch) => {
  dispatch({ type: CART_LIST_REQUEST });
  try {
    const data = localStorage.getItem("cartProducts")
      ? JSON.parse(localStorage.getItem("cartProducts"))
      : null;
    dispatch({ type: CART_LIST_SUCCESS, payload: data });
    localStorage.setItem("cartProducts", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: CART_LIST_FAIL,
      payload: error.message || "Failed to fetch data",
    });
  }
};
