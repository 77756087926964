import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

export const checkOrderHistory = async (token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get("/api/v1/user/order/list", {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateOrderStatus = async (id, status, token) => {
  try {
    const requestBody = {
      status: status,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(
      `/api/v1/user/order/${id}/status`,
      requestBody,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
