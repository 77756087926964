import axios from "axios";
axios.defaults.baseURL = process.env.BASE_URL;
export const checkPermission = async (product_id, token) => {
  //   const requestBody = {
  //     productList: productList,
  //   };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(
    `/api/v1/user/review/product/${product_id}/permission`,
    {
      headers: headers,
    }
  );
  if (response) {
    return response;
  }
};
