import "./App.css";
import ScrollToTop from "./Scroll";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Menu from "./components/common/menu/menu";
import Layout from "./components/Layout/layout";
import ChangePassword from "./components/common/change-password/change-password";
import Login from "./components/common/login/login";
import ForgotPassword from "./components/common/forgot-password/forgot-password";
import Intro from "./components/common/introduce/introduction";
import Event from "./components/common/event-menu/event";
import RegisterForm from "./components/common/register/register";
import Checkout from "./components/common/checkout/checkout";
import Mainpage from "./components/common/mainpage/main-page";
import ProductItem from "./components/common/product-item/product-item";
import Promotion from "./components/common/promotion/promotion";
import CartDetail from "./components/common/cart/cart-detail";
import UserProfile from "./components/common/user-profile/user-profile";
import History from "./components/common/order-history/history";
import GuestInfo from "./components/common/guest-info/guest-info";
import Contact from "./components/common/contact/contact";
import Sidebar from "./components/common/sidebar/sidebar";

// import Event from "./components/common/event";
// import { QueryClientProvider } from '@tanstack/react-query';
// import { queryClient } from './react-query';
import { QueryClient, QueryClientProvider } from "react-query";
import DetailProduct from "./components/common/detail-product/detail-product";
import Orderlist from "./components/common/view-order/detail-order";
function App() {
  const queryClient = new QueryClient();
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<Mainpage />} />
            {/* <Route path="/products/:productId" element={<Menu />} /> */}
            <Route path="/sidebar" element={<Sidebar />} />
            <Route path="/reset_password/:token" element={<ChangePassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/event" element={<Event />} />
            <Route path="/about" element={<Intro />} />
            <Route path="/detail/:productId" element={<ProductItem />} />
            <Route path="/promotion" element={<Promotion />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/user_profile" element={<UserProfile />} />
            {/* <Route path="/event" element={<Event/>} />  */}
            <Route path="/guest_info" element={<GuestInfo />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<CartDetail />} />
            <Route path="/order_history" element={<History />} />
            <Route path="/product_item" element={<ProductItem />} />
            <Route path="/order_detail/:orderId" element={<Orderlist />} />
          </Routes>
        </Layout>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
