import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProcessBar from "../checkout/components/processBar";
import {
  LeftOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { Alert } from "antd";
import { Link } from "react-router-dom";

const GuestInfo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const userType = "0";
  const [successNotice, setSuccessNotice] = useState(false);
  const { t } = useTranslation();

  const validateName = (name) => {
    return name.length >= 7;
  };

  const validatePhone = (phone) => {
    const regex = /^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/;
    return regex.test(phone);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (canSubmit) {
      const guestInfo = { name, email, phone, address, userType };
      localStorage.setItem("guestUserInfo", JSON.stringify(guestInfo));

      setSuccessNotice(true);
      setTimeout(() => {
        window.location.href = "/checkout";
      }, 3000);
    } else {
      setErrorNotice(true);
    }
  };

  const validateAddress = (address) => {
    const minLength = 10;
    const maxLength = 100;
    const addressLength = address.trim().length;
    return addressLength >= minLength && addressLength <= maxLength;
  };

  const canSubmit =
    name.length >= 7 &&
    validateEmail(email) &&
    validatePhone(phone) &&
    validateAddress(address);

  

  const steps = [
    t("Check shopping cart"),
    t("Proceed with payment"),
    t("Payment Methods"),
    t("Order status"),
  ];

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <div className="h-full md:h-dvh w-full bg-[#F2EBE6] mt-24 pb-10 ">
      <div className="absolute z-50">
        {successNotice && (
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.8 }}
            className="fixed top-32 right-2 md:right-10 "
          >
            <Alert
              message={
                <span>
                  <CheckCircleOutlined style={{ color: "green" }} />{" "}
                  {t("Success")}
                </span>
              }
              description={t("Information saved successfully!")}
              type="success"
              closable
              onClose={() => setSuccessNotice(false)}
            />
          </motion.div>
        )}
      </div>

      <div className=" w-full h-1/5 flex justify-center items-center">
        <ProcessBar currentStep={currentStep} steps={steps} />
      </div>

      <div className="w-full h-4/5 flex flex-col md:flex-row mt-4 md:my-0 md:px-4 2xl:px-8 mb-10 ">
        <div className=" w-full h-full rounded-xl bg-white px-6 md:px-12 2xl:px-24 mx-auto pb-20 ">
          <form
            action=""
            onSubmit={handleSubmit}
            noValidate
            className="w-full h-full ml-4"
          >
            <div className="flex mt-8 ">
              <Link to={"/cart"} className="arrow-link md:mt-2">
                <LeftOutlined className="text-black font-bold text-2xl cursor-pointer  hover:text-gray-500" />
              </Link>
              <h1 className="text-2xl md:text-3xl 2xl:text-4xl font-bold ml-4">
                {t(`Guest Information`)}
              </h1>
            </div>

            <div className="w-full flex flex-col pt-12 2xl:px-16">
              <div className="w-4/5 flex flex-col md:flex-row h-fit">
                <div className="md:w-1/3 2xl:w-1/2 mb-6 md:mb-0 h-fit">
                  <h2 className="font-bold text-2xl md:text-xl">
                    {t(`Personal Info *`)}
                  </h2>
                  <p className="text-[#B2B2B2] text-lg font-medium ">
                    {t(`Provide your Personal Info`)}
                  </p>
                </div>

                <div className="ml-4 md:w-2/3 2xl:w-1/2">
                  <div className="mb-4">
                    <label className="block  text-base text-[#B2B2B2] font-semibold">
                      {t(`Fullname`)}
                      <span className="text-red-700 font-bold"> *</span>
                    </label>
                    <input
                      type="text"
                      className={`appearance-none border-2 border-[#b1acac] rounded-xl w-full py-2 px-3 
                      leading-tight focus:outline-none focus:border-[#1890FF] ${
                        name && !validateName(name) ? "border-red-500" : ""
                      }`}
                      placeholder=""
                      maxLength={64}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    {name && !validateName(name) && (
                      <span className="block  text-sm text-red-400 font-medium">
                        {t(`Name must be at least 7 characters long`)}
                      </span>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="block text-base text-[#B2B2B2] font-semibold">
                      {t(`Email`)}
                      <span className="text-red-700 font-bold"> *</span>
                    </label>
                    <input
                      type="email"
                      className={`appearance-none border-2 border-[#b1acac] rounded-xl w-full py-2 px-3 
                      leading-tight focus:outline-none focus:border-[#1890FF] ${
                        email && !validateEmail(email) ? "border-red-500" : ""
                      }`}
                      placeholder=""
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {email && !validateEmail(email) && (
                      <span className="block mt-2 text-sm text-red-400">
                        {t(`Please enter a valid email address`)}
                      </span>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="block text-base text-[#B2B2B2] font-semibold">
                      {t(`Phone Number`)}
                      <span className="text-red-700 font-bold"> *</span>
                    </label>
                    <input
                      type="text"
                      className={`appearance-none border-2 border-[#b1acac] rounded-xl w-full py-2 px-3 
                      leading-tight focus:outline-none focus:border-[#1890FF] ${
                        phone && !validatePhone(phone) ? "border-red-500" : ""
                      }`}
                      placeholder=""
                      onInput={(e) => {
                        e.currentTarget.value = e.currentTarget.value.replace(
                          /[^0-9+()]/g,
                          ""
                        );
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    {phone && !validatePhone(phone) && (
                      <span className="block mt-2 text-sm text-red-400">
                        {t(`Please enter a valid phone number`)}
                      </span>
                    )}
                  </div>

                  {/* <div className="mb-4">
                    <label className="block text-base text-[#B2B2B2] font-semibold">
                      {t("Address")}
                      <span className="text-red-700 font-bold"> *</span>
                    </label>
                    <input
                      type="text"
                      className={`appearance-none border-2 border-[#b1acac] rounded-xl w-full py-2 px-3 
                      leading-tight focus:outline-none focus:border-[#1890FF] ${
                        address && !address.trim() ? "border-red-500" : ""
                      }`}
                      placeholder=""
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                    {address && !address.trim() && (
                      <span className="block mt-2 text-sm text-red-400">
                        {t(`Please enter your address`)}
                      </span>
                    )}
                  </div> */}
                  <div className="mb-4">
                    <label className="block text-base text-[#B2B2B2] font-semibold">
                      {t("Address")}
                      <span className="text-red-700 font-bold"> *</span>
                    </label>
                    <input
                      type="text"
                      className={`appearance-none border-2 border-[#b1acac] rounded-xl w-full py-2 px-3 
                           leading-tight focus:outline-none focus:border-[#1890FF] ${
                             address && !validateAddress(address)
                               ? "border-red-500"
                               : ""
                           }`}
                      placeholder=""
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                    {address && !validateAddress(address) && (
                      <span className="block mt-2 text-sm text-red-400">
                        {t(`ValidateAddressGuest`)}
                      </span>
                    )}
                  </div>

                  <div className="flex justify-end">
                    <Link to={"/cart"}>
                      <button
                        className="border-2 border-gray-500 text-black h-12  w-20 rounded-xl shadow-md font-semibold
                        hover:text-white hover:bg-[#FA7070] duration-200 ease-in-out"
                      >
                        {t("Close")}
                      </button>
                    </Link>

                    <button
                      type="submit"
                      disabled={!canSubmit}
                      className="ml-4 bg-[#1890FF] text-white font-semibold text-center h-12  w-32 rounded-xl shadow-md
                        hover:bg-[#47a3f9] disabled:cursor-not-allowed disabled:bg-gradient-to-br disabled:from-gray-100 disabled:to-gray-300 disabled:text-gray-400 group-invalid:pointer-events-none group-invalid:bg-gradient-to-br group-invalid:from-gray-100 group-invalid:to-gray-300 group-invalid:text-gray-400 group-invalid:opacity-70"
                    >
                      {t("Save & Continue")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestInfo;
